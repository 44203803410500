import React, { FunctionComponent, useContext } from "react";
import { Context } from "./Context";
import styles from "./Tab.module.scss";
import cn from "classnames";

export type TitleProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  tabItemIndex?: number;
  icon?: string;
};

export interface TitleInterface extends FunctionComponent<TitleProps> {}

export const Title: TitleInterface = ({
  tabItemIndex = 0,
  children,
  icon,
  ...props
}) => {
  const [currentTabItemIndex, setCurrentTabItemIndex] = useContext(Context);
  return (
    <button
      {...props}
      type="button"
      className={cn(styles.tabTitle, {
        [styles.tabTitleShow]: currentTabItemIndex === tabItemIndex,
      })}
      onClick={() => setCurrentTabItemIndex(tabItemIndex)}
    >
      <img src={icon} alt="" width="40px" height="40px" />
      <span>{children}</span>
    </button>
  );
};
Title.displayName = "Tab.Item.Title";
