import { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import styles from "./Menu.module.scss";

const className: (props: { isActive: boolean }) => string | undefined = ({
  isActive,
}) => (isActive ? styles.active : undefined);

type MenuProps = {
  isMenuOpened?: boolean;
  onCloseMenu?: () => void;
};

export const Menu: React.FC<MenuProps> = ({ isMenuOpened, onCloseMenu }) => {
  const { t } = useTranslation();

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const handleOutsideClick = (e: unknown) => {
    // @ts-expect-error: fix later
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      onCloseMenu?.();
    }
  };

  return (
    <nav
      className={cn(styles.menu, { [styles.active]: isMenuOpened })}
      ref={menuRef}
    >
      <NavLink to="/" onClick={onCloseMenu} className={className}>
        {t("global.about-us")}
      </NavLink>
      <NavLink to="/online" onClick={onCloseMenu} className={className}>
        {t("global.money-transfers")}
      </NavLink>
      <NavLink to="/processing" onClick={onCloseMenu} className={className}>
        {t("global.banking-preprocessing")}
      </NavLink>
    </nav>
  );
};
