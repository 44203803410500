import React, { HTMLAttributes, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./StrengthMeter.module.scss";

interface StrengthMeterProps extends HTMLAttributes<HTMLDivElement> {
  value?: string;
}

const widths = ["20%", "40%", "60%", "80%", "100%"];
const colors = ["#D73F40", "#DC6551", "#F2B84F", "#BDE952", "#3ba62f"];
const titles = [
  "global.vulnerable-password",
  "global.weak-password",
  "global.medium-password",
  "global.good-password",
  "global.strong-password",
];

export const StrengthMeter: React.FC<StrengthMeterProps> = ({ value = "" }) => {
  const { t } = useTranslation();

  const strengthIndex = useMemo(() => {
    let point = 0;

    if (value.length >= 6) {
      const arrayTest = [/[0-9]/, /[a-z]/, /[A-Z]/, /[^0-9a-zA-Z]/];
      arrayTest.forEach((item) => {
        if (item.test(value)) {
          point += 1;
        }
      });
    }

    return point;
  }, [value]);

  if (!value) return null;

  return (
    <div className={styles.container}>
      <div className={styles.slide}>
        <div
          className={styles.indicator}
          style={{
            backgroundColor: colors[strengthIndex],
            width: widths[strengthIndex],
          }}
        ></div>
      </div>
      <div>{t(titles[strengthIndex])}</div>
    </div>
  );
};
