import React, { FunctionComponent } from "react";
import styles from "./PrimaryBlock.module.scss";
import cn from "classnames";

export interface TitleInterface
  extends FunctionComponent<React.HTMLAttributes<HTMLHeadingElement>> {}

export const Title: TitleInterface = ({
  className = null,
  children,
  ...props
}) => (
  <h1 className={cn(styles.primaryBlockTitle, className)} {...props}>
    {children}
  </h1>
);
Title.displayName = "PrimaryBlock.Title";
