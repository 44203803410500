import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import rocket from "../../../assets/prime/rocket.x1.5.png";
import integrationArrowSmall from "../../../assets/prime/integration-arrow-small.x1.5.png";
import integrationArrowMiddle from "../../../assets/prime/integration-arrow-middle.x1.5.png";
import integrationArrowBig from "../../../assets/prime/integration-arrow-big.x1.5.png";
import { Box } from "components";
import styles from "./IntegrationBox.module.scss";

export const IntegrationBox: FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      variant="light"
      position="right"
      animated="scroll"
      className={styles.boxIntegration}
    >
      <Box.Title
        dangerouslySetInnerHTML={{
          __html: t("processing.integration.title"),
        }}
      />
      <Box.Body>
        <ul>
          <li>{t("processing.integration.body-1")}</li>
          <li>{t("processing.integration.body-2")}</li>
          <li>{t("processing.integration.body-3")}</li>
          <li>{t("processing.integration.body-4")}</li>
        </ul>
      </Box.Body>
      <Box.Image>
        <img
          src={rocket}
          alt=""
          width="338px"
          height="229px"
          className={styles.rocket}
        />
        <img
          src={integrationArrowSmall}
          alt=""
          width="41px"
          height="56px"
          className={styles.integrationArrowSmall}
        />
        <img
          src={integrationArrowMiddle}
          alt=""
          width="82px"
          height="93px"
          className={styles.integrationArrowMiddle}
        />
        <img
          src={integrationArrowBig}
          alt=""
          width="168px"
          height="190px"
          className={styles.integrationArrowBig}
        />
      </Box.Image>
    </Box>
  );
};
