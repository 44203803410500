import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "components";
import styles from "./MainFunctionBox.module.scss";

export const MainFunctionBox: FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      variant="light"
      position="bottom"
      animated="scroll"
      className={styles.mainFunction}
    >
      <Box.Title>{t("processing.functions.title")}</Box.Title>
      <Box.Body>
        <ul>
          <li>{t("processing.functions.body-1")}</li>
          <li>{t("processing.functions.body-2")}</li>
        </ul>
      </Box.Body>
      <Box.Image>
        <div className={styles.mainFunctionSpriteGreen} />
        <div className={styles.mainFunctionSpriteBlue} />
        <div className={styles.mainFunctionSpriteWhite} />
      </Box.Image>
    </Box>
  );
};
