import React, { FunctionComponent, useContext } from "react";
import styles from "./Accordion.module.scss";
import { ItemContext } from "./ItemContext";
import cn from "classnames";
import { Collapse } from "../Collapse";

type BodyProps = React.HTMLAttributes<HTMLElement>;

export interface BodyInterface extends FunctionComponent<BodyProps> {}

export const Body: BodyInterface = ({ className = null, ...props }) => {
  const [show] = useContext(ItemContext);
  return (
    <Collapse
      {...props}
      as="dd"
      open={show}
      className={cn(styles.accordionDescription, className)}
      showClassName={styles.accordionDescriptionShow}
      timeout={300}
      transitionClassName={styles.accordionDescriptionTransition}
    />
  );
};
Body.displayName = "Accordion.Item.Body";
