import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import cn from "classnames";
import { Box } from "components";
import { RiskMitigationBg } from "assets";
import bgImageEn from "assets/img/risk-mitigation.en.x1.5.png";
import bgImageRu from "assets/risk-mitigation.x1.5.png";
import styles from "./ComponentsRiskMitigationBox.module.scss";

export const ComponentsRiskMitigationBox: FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      variant="dark"
      animated="scroll"
      position="right"
      className={styles.componentsRiskMitigation}
    >
      <Box.Title
        dangerouslySetInnerHTML={{
          __html: t("processing.risk.title"),
        }}
      />
      <Box.Body>
        <ul>
          <li>AML</li>
          <li>{t("processing.risk.body-1")}</li>
          <li>{t("processing.risk.body-2")}</li>
        </ul>
      </Box.Body>
      <Box.Image
        style={{
          // @ts-expect-error: fix
          "--bg-image": `url(${i18n.language === "ru" ? bgImageRu : bgImageEn})`,
        }}
      >
        <RiskMitigationBg className={styles.riskMitigationSpriteBg} />
        <div className={cn(styles.riskMitigationSpriteAntifrod)} />
        <div className={styles.riskMitigationSpriteAml} />
        <div className={styles.riskMitigationSpriteCompliance} />
      </Box.Image>
    </Box>
  );
};
