import React, { useEffect, useRef, ChangeEvent } from "react";
import cn from "classnames";
import styles from "./Radio.module.scss";

export type RadioType = {
  label: string;
  value: string;
};

export type RadioProps = {
  label: RadioType["label"];
  value: RadioType["value"];
  selected: RadioType["value"];
  groupName: string;
  hasError: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement> | KeyboardEvent) => void;
  onBlur?: () => void;
};

export const Radio = (props: RadioProps) => {
  const { value, label, selected, groupName, hasError, onChange, onBlur } =
    props;

  const ref = useRef<HTMLLabelElement>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
  };

  const handleBlur = () => onBlur?.();

  useEffect(() => {
    const label = ref.current;

    if (!label) return;

    const handleEnterKeyDown = (event: KeyboardEvent) => {
      if (document.activeElement === label && event.key === "Space") {
        onChange?.(event);
      }
    };

    label.addEventListener("keydown", handleEnterKeyDown);

    return () => {
      label.removeEventListener("keydown", handleEnterKeyDown);
    };
  }, [value, onChange]);

  const isChecked = value === selected;

  return (
    <label
      className={styles.radio}
      data-checked={isChecked}
      key={value}
      ref={ref}
    >
      <input
        className={styles.input}
        type="radio"
        name={groupName}
        checked={isChecked}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <span className={cn(styles.label, hasError && styles.invalid)}>
        {label}
      </span>
    </label>
  );
};
