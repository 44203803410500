import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "components";
import { whiteLabel } from "assets/img";

import styles from "./WhiteLabelBox.module.scss";

export const WhiteLabelBox: FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      variant="yellow"
      animated="scroll"
      position="bottom"
      className={styles.whiteLabel}
    >
      <Box.Title
        style={{ textTransform: "uppercase" }}
        dangerouslySetInnerHTML={{
          __html: t("processing.white-label.title"),
        }}
      />
      <Box.Body>{t("processing.white-label.body")}</Box.Body>
      <Box.Image>
        <img src={whiteLabel} width="181px" height="195px" alt="" />
      </Box.Image>
    </Box>
  );
};
