import React, { FunctionComponent } from "react";
import { Box } from "../Box";
import { BoxProps } from "../Box/Box";
import i18n from "i18next";
import bgImageEn from "assets/img/home-countries.en.x1.5.png";
import bgImageRu from "assets/home-countries.x1.5.png";
import style from "./CountryBox.module.scss";

export const CountryBox: FunctionComponent<Partial<BoxProps>> = (props) => {
  return (
    <Box {...props}>
      <Box.Image
        style={{
          // @ts-expect-error: fix
          "--bg-image": `url(${i18n.language === "ru" ? bgImageRu : bgImageEn})`,
        }}
        className={style.image}
      ></Box.Image>
    </Box>
  );
};
