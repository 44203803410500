import React, { createElement, ReactNode } from "react";
// eslint-disable-next-line import/named
import { Link, LinkProps } from "react-router-dom";
import styles from "./Button.module.scss";
import cn from "classnames";
import icons from "../Icons";

type ButtonProps = {
  variant?: "primary" | "secondary" | "light" | "gray";
  rightIcon?: ReactNode | keyof typeof icons;
  leftIcon?: ReactNode | keyof typeof icons;
  wide?: boolean;
  narrow?: boolean;
  small?: boolean;
} & (
  | ({ as: "button" } & React.ButtonHTMLAttributes<HTMLButtonElement>)
  | ({ as: "a" } & React.AnchorHTMLAttributes<HTMLAnchorElement>)
  | ({ as?: typeof Link } & LinkProps)
);
export const Button: React.FC<ButtonProps> = ({
  variant,
  as: Component = Link,
  className,
  leftIcon,
  rightIcon,
  wide = false,
  narrow = false,
  small = false,
  children,
  ...props
}) => {
  if (typeof rightIcon == "string") {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const RightIcons = icons[rightIcon];
    rightIcon = <RightIcons />;
  }

  if (typeof leftIcon == "string") {
    // @ts-expect-error: fix later
    const LeftIcons = icons[leftIcon];
    leftIcon = <LeftIcons />;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <Component
      {...props}
      className={cn(
        styles.btn,
        {
          [styles.primary]: variant == "primary",
          [styles.secondary]: variant == "secondary",
          [styles.light]: variant == "light",
          [styles.gray]: variant == "gray",
          [styles.wide]: wide,
          [styles.narrow]: narrow,
          [styles.small]: small,
        },
        className,
      )}
    >
      {leftIcon && <span className={styles.btnLeftIcon}>{leftIcon}</span>}
      {children}
      {rightIcon && <span className={styles.btnRightIcon}>{rightIcon}</span>}
    </Component>
  );
};
