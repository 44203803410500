import React from "react";
import { useTranslation } from "react-i18next";
import { Form, useFormikContext } from "formik";
import type { FormikValues } from "formik";
import { Button, Input, InputPassword } from "components";
import styles from "./ChangePasswordForm.module.scss";

type ChangePasswordFormProps = {
  formError: string;
};

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  formError,
}) => {
  const { t } = useTranslation();

  const { values, touched, errors, handleChange, handleBlur, isSubmitting } =
    useFormikContext() as FormikValues;

  return (
    <Form>
      {formError && (
        <div className={styles.formError}>
          <p>{formError}</p>
        </div>
      )}

      <Input
        label={t("change-password.code")}
        name="code"
        value={values.code}
        error={touched.code && errors.code}
        onChange={handleChange}
        onBlur={handleBlur}
        containerClassName={styles.code}
      />

      <InputPassword
        label={t("change-password.code")}
        name="password"
        value={values.password}
        error={touched.password && errors.password}
        onChange={handleChange}
        onBlur={handleBlur}
        hasStrengthMeter={true}
        containerClassName={styles.password}
      />

      <InputPassword
        label={t("change-password.password-confirm")}
        name="passwordConfirm"
        value={values.passwordConfirm}
        error={touched.passwordConfirm && errors.passwordConfirm}
        onChange={handleChange}
        onBlur={handleBlur}
        containerClassName={styles.password}
      />

      <Button as="button" type="submit" variant="primary" wide={true}>
        {t("change-password.done")}
      </Button>
    </Form>
  );
};
