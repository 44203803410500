import React, { FunctionComponent, ReactNode } from "react";
import styles from "./PrimaryBlock.module.scss";
import cn from "classnames";
import { IconCircle } from "../Icons";

type PrimaryBlockProps = React.HTMLAttributes<HTMLDivElement> & {
  icon: ReactNode;
};

export interface InfoItemInterface
  extends FunctionComponent<PrimaryBlockProps> {}

export const InfoItem: InfoItemInterface = ({
  children,
  className = null,
  icon,
  ...props
}) => (
  <div className={cn(styles.primaryBlockInfoItem, className)} {...props}>
    <IconCircle variant="light">{icon}</IconCircle>
    <div>{children}</div>
  </div>
);
InfoItem.displayName = "PrimaryBlock.Info.Item";
