import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { PrimaryBlock, Button } from "components";
import { interfaceCommon, control2 } from "assets/img";
import styles from "./PrimaryBlockOnline.module.scss";

export const PrimaryBlockOnline: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <PrimaryBlock
      className={styles.primaryBlockOnline}
      containerClassName={styles.primaryBlockContainerOnline}
    >
      <PrimaryBlock.Title
        className={styles.animationOffsetY}
        style={{ transform: "translateY(40px)" }}
        dangerouslySetInnerHTML={{ __html: t("online.primary-block.title") }}
      />

      <PrimaryBlock.Body
        className={styles.animationOffsetY}
        style={{ transform: "translateY(30px)" }}
      >
        {t("online.primary-block.body")}
      </PrimaryBlock.Body>

      <PrimaryBlock.Footer
        className={styles.animationOffsetY}
        style={{ transform: "translateY(60px)" }}
      >
        <Button to="/" variant="secondary" disabled={true}>
          {t("online.primary-block.button")}
        </Button>
      </PrimaryBlock.Footer>

      <PrimaryBlock.Image>
        <img
          src={interfaceCommon}
          className={cn(styles.animationOffsetY, styles.interfaceCommon)}
          alt=""
        />
        <img
          src={control2}
          className={cn(styles.animationOffsetY, styles.control2)}
          alt=""
        />
        <div className={cn(styles.coinSprite, styles.coinSpriteKyrgyzstan)} />
        <div className={cn(styles.coinSprite, styles.coinSpriteRussian)} />
        <div className={cn(styles.coinSprite, styles.coinSpriteCrypto)} />
        <div className={cn(styles.coinSprite, styles.coinSpriteUzbekistan)} />
        <div className={cn(styles.coinSprite, styles.coinSpriteArmenia)} />
        <div className={cn(styles.coinSprite, styles.coinSpriteKazakhstan)} />
        <div className={cn(styles.coinSprite, styles.coinSpriteTajikistan)} />
      </PrimaryBlock.Image>
    </PrimaryBlock>
  );
};
