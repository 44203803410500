import React from "react";
import { useTranslation } from "react-i18next";
import { Form, useFormikContext } from "formik";
import type { FormikValues } from "formik";
import { Button, InputGroup, Input, RadioGroup } from "components";

export const ConnectionRequestForm: React.FC = () => {
  const { t } = useTranslation();

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    validateField,
    isSubmitting,
  } = useFormikContext() as FormikValues;

  return (
    <Form>
      <InputGroup title={t("connection-request.group.about")}>
        <Input
          label={t("connection-request.label.company")}
          name="company"
          value={values.company}
          error={touched.company && errors.company}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={t("connection-request.label.site")}
          name="site"
          value={values.site}
          error={touched.site && errors.site}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputGroup>
      <InputGroup title={t("connection-request.group.contacts")}>
        <Input
          label={t("connection-request.label.name")}
          name="name"
          value={values.name}
          error={touched.name && errors.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label={t("connection-request.label.phone")}
          name="phone"
          inputMode="tel"
          value={values.phone}
          error={touched.phone && errors.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          mask="+7(999) 999-99-99"
        />
        <Input
          label={t("connection-request.label.email")}
          name="email"
          inputMode="email"
          value={values.email}
          error={touched.email && errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputGroup>
      <RadioGroup
        title={t("connection-request.label.type")}
        name="type"
        value={values.type}
        error={touched.type && errors.type}
        onChange={handleChange}
        // onBlur={handleBlur}
        options={[
          {
            label: t("connection-request.label.e-commerce"),
            value: "e-commerce",
          },
          {
            label: t("connection-request.label.retail"),
            value: "retail",
          },
        ]}
      ></RadioGroup>
      <Button as="button" type="submit" variant="secondary" wide={true}>
        {t("connection-request.button")}
      </Button>
    </Form>
  );
};
