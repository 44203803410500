import React from "react";
import styles from "./Container.module.scss";
import cn from "classnames";

type ContainerProps = {
  as?: "section" | "footer" | "div";
  centered?: boolean;
  columned?: boolean;
} & React.HTMLAttributes<HTMLElement>;

export const Container: React.FC<ContainerProps> = ({
  as: Component = "section",
  className,
  centered,
  columned,
  ...props
}) => {
  return (
    <Component
      className={cn(
        styles.container,
        {
          [styles.centered]: centered,
          [styles.columned]: columned,
        },
        className,
      )}
      {...props}
    />
  );
};
