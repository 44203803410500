import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import type { FormikValues } from "formik";
import { object } from "yup";
import { useSignIn } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { api } from "api";
import { useValidators } from "helpers/hooks";
import { FormLoader } from "components";
import { RegistrationForm } from "./RegistrationForm";
import { VerificationForm } from "./VerificationForm";
import bgImageEn from "assets/img/signUp.en.x1.5.png";
import bgImageRu from "assets/img/signUp.x1.5.png";
import styles from "./SignUp.module.scss";

const registrationInitialValues = {
  name: "",
  email: "",
  phone: "",
  citizenship: "RU",
  password: "",
  passwordConfirm: "",
  isAgree: false,
};

const verificationInitialValues = {
  code: "",
};

export const SignUp: React.FC = () => {
  const { t } = useTranslation();
  const {
    nameValidator,
    emailValidator,
    phoneValidator,
    citizenshipValidator,
    passwordValidator,
    passwordConfirmValidator,
    agreementValidator,
    codeValidator,
  } = useValidators();

  const signIn = useSignIn();
  const navigate = useNavigate();

  const [step, setStep] = useState("registration");
  const [isLoading, setIsLoading] = useState(false);
  const [emailForVerification, setEmailForVerification] = useState("");
  const [registrationFormError, setRegistrationFormError] = useState("");
  const [verificationFormError, setVerificationFormError] = useState("");

  const standardErrorText = t("errors.unknown-error");

  async function handleRegistrationSubmit(values: FormikValues) {
    setIsLoading(true);
    setRegistrationFormError("");

    try {
      const result = await api.signUp({
        name: values.name,
        email: values.email,
        phone: values.phone,
        citizenship: values.citizenship,
        pass: values.password,
        currency: "RUB",
        type: "person",
      });

      setEmailForVerification(values.email);

      setStep("verification");
    } catch (error: unknown) {
      if (typeof error === "string") {
        setRegistrationFormError(error);
      } else if (error instanceof Error) {
        setRegistrationFormError(error.message);
      } else {
        setRegistrationFormError(standardErrorText);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function handleVerificationSubmit(values: FormikValues) {
    setIsLoading(true);
    setVerificationFormError("");

    try {
      const result = await api.verificateEmail({
        email: emailForVerification,
        code: values.code,
        type: "person",
      });

      if (
        signIn({
          tokenType: "Bearer",
          token: result.token,
          expiresIn: result.expire_at,
          authState: {},
        })
      ) {
        navigate("/");
        setStep("registration");
      } else {
        throw new Error(standardErrorText);
      }
    } catch (error: unknown) {
      if (typeof error === "string") {
        setVerificationFormError(error);
      } else if (error instanceof Error) {
        setVerificationFormError(error.message);
      } else {
        setVerificationFormError(standardErrorText);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function handleResendCode() {
    setIsLoading(true);

    try {
      const result = await api.resendCode({
        email: emailForVerification,
        type: "person",
      });
    } catch (error: unknown) {
      if (typeof error === "string") {
        setVerificationFormError(error);
      } else if (error instanceof Error) {
        setVerificationFormError(error.message);
      } else {
        setVerificationFormError(standardErrorText);
      }
    } finally {
      setIsLoading(false);
    }
  }

  function handleCancelRegistration() {
    setStep("registration");
  }

  return (
    <section
      className={styles.page}
      style={{
        // @ts-expect-error: fix
        "--bg-image": `url(${i18n.language === "ru" ? bgImageRu : bgImageEn})`,
      }}
    >
      <ul className={styles.benefits}>
        <li className={styles.minSetup}>{t("sign-up.min-setup")}</li>
        <li className={styles.freeConnect}>{t("sign-up.free-connect")}</li>
        <li className={styles.support}>{t("sign-up.support")}</li>
      </ul>
      <div className={styles.container}>
        {step === "registration" && (
          <Formik
            initialValues={registrationInitialValues}
            validationSchema={object().shape({
              name: nameValidator,
              email: emailValidator,
              phone: phoneValidator,
              citizenship: citizenshipValidator,
              password: passwordValidator,
              passwordConfirm: passwordConfirmValidator,
              isAgree: agreementValidator,
            })}
            onSubmit={handleRegistrationSubmit}
          >
            <RegistrationForm formError={registrationFormError} />
          </Formik>
        )}

        {step === "verification" && (
          <Formik
            initialValues={verificationInitialValues}
            validationSchema={object({
              code: codeValidator,
            })}
            onSubmit={handleVerificationSubmit}
          >
            <VerificationForm
              formError={verificationFormError}
              onResendCode={handleResendCode}
              onCancel={handleCancelRegistration}
            />
          </Formik>
        )}

        {isLoading && <FormLoader />}
      </div>
    </section>
  );
};
