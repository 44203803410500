import React, {
  FunctionComponent,
  isValidElement,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./PrimaryBlock.module.scss";
import cn from "classnames";
import { Container } from "../Container";
import { Title, TitleInterface } from "./Title";
import { Body, BodyInterface } from "./Body";
import { Image, ImageInterface } from "./Image";
import { Info, InfoInterface } from "./Info";
import { Footer, FooterInterface } from "./Footer";
import { ArrowDown } from "../Icons";

type PrimaryBlockProps = {
  containerClassName?: string;
  img?: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

interface PrimaryBlockInterface extends FunctionComponent<PrimaryBlockProps> {
  Title: TitleInterface;
  Body: BodyInterface;
  Info: InfoInterface;
  Image: ImageInterface;
  Footer: FooterInterface;
}

function filter(
  children: ReactNode,
  callback: (child: ReactElement) => boolean,
): Array<Exclude<ReactNode, boolean | null | undefined>> {
  return React.Children.toArray(children).filter(
    (child) => isValidElement(child) && callback(child),
  );
}

export const PrimaryBlock: PrimaryBlockInterface = ({
  className = null,
  containerClassName = null,
  img,
  children,
  ...props
}) => {
  const ref = useRef<HTMLElement>(null);
  function onDown() {
    if (ref.current) {
      window.scrollTo({
        behavior: "smooth",
        top: ref.current.scrollTop + ref.current.offsetHeight - 68,
      });
    }
  }
  const [start, setStart] = useState(false);
  useEffect(() => setStart(true), []);
  return (
    <section
      ref={ref}
      className={cn(
        styles.primaryBlock,
        { [styles.primaryBlockTransition]: start },
        className,
      )}
    >
      <Container
        as="div"
        {...props}
        className={cn(styles.primaryBlockContainer, containerClassName)}
      >
        {filter(children, (child) => child.type !== Image)}
      </Container>
      {filter(children, (child) => child.type === Image)}
      <button
        type="button"
        className={styles.primaryBlockArrow}
        onClick={onDown}
      >
        <ArrowDown />
      </button>
    </section>
  );
};
PrimaryBlock.Title = Title;
PrimaryBlock.Body = Body;
PrimaryBlock.Info = Info;
PrimaryBlock.Image = Image;
PrimaryBlock.Footer = Footer;
