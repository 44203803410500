import i18n from "i18next";
import antifrodRu from "../prime/antifrod.x1.5.png";
import antifrodEn from "../prime/antifrod.en.x1.5.png";
import apiRu from "../api.x1.5.png";
import apiEn from "./api.en.x1.5.png";
import amlRu from "../prime/aml.x1.5.png";
import complianceRu from "../prime/compliance.x1.5.png";
import complianceEn from "./compliance.en.x1.5.png";
import supportRu from "../prime/support.x1.5.png";
import interfaceCommonRu from "../prime/interface-common.x1.5.png";
import interfaceCommonEn from "./interface-common.en.x1.5.png";
import controlRu from "../prime/control-1.x1.5.png";
import controlEn from "./control-1.en.x1.5.png";
import control2Ru from "../prime/control-2.x1.5.png";
import control2En from "./control-2.en.x1.5.png";
import doneRu from "../prime/done.x1.5.png";
import doneEn from "./done.en.x1.5.png";
import img4Ru from "../prime/img-4.x1.5.png";
import img4En from "./img-4.en.x1.5.png";
import antifrodRoundRu from "../prime/antifrod-round.x1.5.png";
import antifrodRoundEn from "./antifrod-round.en.x1.5.png";
import complianceDarkRu from "../prime/compliance-dark.x1.5.png";
import complianceDarkEn from "./compliance-dark.en.x1.5.png";
import bankCardRu from "../prime/bank-card.x1.5.png";
import bankCardEn from "./bank-card.en.x1.5.png";
import transferRu from "../prime/transfer.x1.5.png";
import transferEn from "./transfer.en.x1.5.png";
import whiteLabelRu from "../white-label.x1.5.png";
import whiteLabelEn from "./white-label.en.x1.5.png";
import simpleSendRu from "../simple-send.x1.5.png";
import simpleSendEn from "./simple-send.en.x1.5.png";
import sendingMethodRu from "../sending-method.x1.5.png";
import sendingMethodEn from "./sending-method.en.x1.5.png";
import sourcesRu from "../sources.x1.5.png";
import sourcesEn from "./sources.en.x1.5.png";
import tajikistanStep1Ru from "../tajikistan-step-1.x1.5.png";
import tajikistanStep2Ru from "../tajikistan-step-2.x1.5.png";
import tajikistanStep3Ru from "../tajikistan-step-3.x1.5.png";
import tajikistanStep4Ru from "../tajikistan-step-4.x1.5.png";
import tajikistanStep1En from "./tajikistan-step-1.en.x1.5.png";
import tajikistanStep2En from "./tajikistan-step-2.en.x1.5.png";
import tajikistanStep3En from "./tajikistan-step-3.en.x1.5.png";
import tajikistanStep4En from "./tajikistan-step-4.en.x1.5.png";
import uzbekistanStep1Ru from "../uzbekistan-step-1.x1.5.png";
import uzbekistanStep2Ru from "../uzbekistan-step-2.x1.5.png";
import uzbekistanStep3Ru from "../uzbekistan-step-3.x1.5.png";
import uzbekistanStep4Ru from "../uzbekistan-step-4.x1.5.png";
import uzbekistanStep5Ru from "../uzbekistan-step-5.x1.5.png";
import uzbekistanStep6Ru from "../uzbekistan-step-6.x1.5.png";
import uzbekistanStep1En from "./uzbekistan-step-1.en.x1.5.png";
import uzbekistanStep2En from "./uzbekistan-step-2.en.x1.5.png";
import uzbekistanStep3En from "./uzbekistan-step-3.en.x1.5.png";
import uzbekistanStep4En from "./uzbekistan-step-4.en.x1.5.png";
import uzbekistanStep5En from "./uzbekistan-step-5.en.x1.5.png";
import uzbekistanStep6En from "./uzbekistan-step-6.en.x1.5.png";

export const antifrod = i18n.language === "ru" ? antifrodRu : antifrodEn;
export const api = i18n.language === "ru" ? apiRu : apiEn;
export const aml = i18n.language === "ru" ? amlRu : amlRu;
export const compliance = i18n.language === "ru" ? complianceRu : complianceEn;
export const support = i18n.language === "ru" ? supportRu : supportRu;
export const interfaceCommon =
  i18n.language === "ru" ? interfaceCommonRu : interfaceCommonEn;
export const control = i18n.language === "ru" ? controlRu : controlEn;
export const control2 = i18n.language === "ru" ? control2Ru : control2En;
export const done = i18n.language === "ru" ? doneRu : doneEn;
export const img4 = i18n.language === "ru" ? img4Ru : img4En;
export const antifrodRound =
  i18n.language === "ru" ? antifrodRoundRu : antifrodRoundEn;
export const complianceDark =
  i18n.language === "ru" ? complianceDarkRu : complianceDarkEn;
export const bankCard = i18n.language === "ru" ? bankCardRu : bankCardEn;
export const transfer = i18n.language === "ru" ? transferRu : transferEn;
export const whiteLabel = i18n.language === "ru" ? whiteLabelRu : whiteLabelEn;
export const simpleSend = i18n.language === "ru" ? simpleSendRu : simpleSendEn;
export const sendingMethod =
  i18n.language === "ru" ? sendingMethodRu : sendingMethodEn;
export const sources = i18n.language === "ru" ? sourcesRu : sourcesEn;
export const tajikistanStep1 =
  i18n.language === "ru" ? tajikistanStep1Ru : tajikistanStep1En;
export const tajikistanStep2 =
  i18n.language === "ru" ? tajikistanStep2Ru : tajikistanStep2En;
export const tajikistanStep3 =
  i18n.language === "ru" ? tajikistanStep3Ru : tajikistanStep3En;
export const tajikistanStep4 =
  i18n.language === "ru" ? tajikistanStep4Ru : tajikistanStep4En;
export const uzbekistanStep1 =
  i18n.language === "ru" ? uzbekistanStep1Ru : uzbekistanStep1En;
export const uzbekistanStep2 =
  i18n.language === "ru" ? uzbekistanStep2Ru : uzbekistanStep2En;
export const uzbekistanStep3 =
  i18n.language === "ru" ? uzbekistanStep3Ru : uzbekistanStep3En;
export const uzbekistanStep4 =
  i18n.language === "ru" ? uzbekistanStep4Ru : uzbekistanStep4En;
export const uzbekistanStep5 =
  i18n.language === "ru" ? uzbekistanStep5Ru : uzbekistanStep5En;
export const uzbekistanStep6 =
  i18n.language === "ru" ? uzbekistanStep6Ru : uzbekistanStep6En;
