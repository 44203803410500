import React, { Fragment, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "../Box";
import { Tab, TabProps } from "../Tab";
import { ScrollableCarousel } from "../ScrollableCarousel";
import {
  tajikistanStep1,
  tajikistanStep2,
  tajikistanStep3,
  tajikistanStep4,
  uzbekistanStep1,
  uzbekistanStep2,
  uzbekistanStep3,
  uzbekistanStep4,
  uzbekistanStep5,
  uzbekistanStep6,
} from "assets/img";
import iconTajikistan from "assets/icon-tajikistan-circle.x1.5.png";
import iconUzbekistan from "assets/icon-uzbekistan-circle.x1.5.png";
import styles from "./StepByStepTab.module.scss";

export const StepByStepTab: FunctionComponent<Partial<TabProps>> = () => {
  const { t } = useTranslation();

  return (
    <Tab className={styles.tab}>
      <Tab.Item>
        <Tab.Item.Title icon={iconTajikistan}>
          {t("steps-tajikistan.title")}
        </Tab.Item.Title>
        <Tab.Item.Body>
          <ScrollableCarousel>
            <Box variant="light" number={1} className={styles.box}>
              <Box.Title>{t("steps-tajikistan.item-1.title")}</Box.Title>
              <Box.Body
                dangerouslySetInnerHTML={{
                  __html: t("steps-tajikistan.item-1.body"),
                }}
              />
              <Box.Image>
                <img
                  src={tajikistanStep1}
                  width="320px"
                  height="291px"
                  alt=""
                />
              </Box.Image>
            </Box>
            <Box variant="light" number={2} className={styles.box}>
              <Box.Title>{t("steps-tajikistan.item-2.title")}</Box.Title>
              <Box.Body
                dangerouslySetInnerHTML={{
                  __html: t("steps-tajikistan.item-2.body"),
                }}
              />
              <Box.Image>
                <img
                  src={tajikistanStep2}
                  width="320px"
                  height="291px"
                  alt=""
                />
              </Box.Image>
            </Box>
            <Box variant="light" number={3} className={styles.box}>
              <Box.Body
                dangerouslySetInnerHTML={{
                  __html: t("steps-tajikistan.item-3.body"),
                }}
              />
              <Box.Image>
                <img
                  src={tajikistanStep3}
                  width="320px"
                  height="291px"
                  alt=""
                />
              </Box.Image>
            </Box>
            <Box variant="light" number={4} className={styles.box}>
              <Box.Title>{t("steps-tajikistan.item-4.title")}</Box.Title>
              <Box.Body
                dangerouslySetInnerHTML={{
                  __html: t("steps-tajikistan.item-4.body"),
                }}
              />
              <Box.Image>
                <img
                  src={tajikistanStep4}
                  width="194px"
                  height="355px"
                  alt=""
                />
              </Box.Image>
            </Box>
          </ScrollableCarousel>
        </Tab.Item.Body>
      </Tab.Item>
      <Tab.Item>
        <Tab.Item.Title icon={iconUzbekistan}>
          {t("steps-uzbekistan.title")}
        </Tab.Item.Title>
        <Tab.Item.Body>
          <ScrollableCarousel>
            <Box variant="light" number={1}>
              <Box.Title>{t("steps-uzbekistan.item-1.title")}</Box.Title>
              <Box.Body>{t("steps-uzbekistan.item-1.body")}</Box.Body>
              <Box.Image>
                <img
                  src={uzbekistanStep1}
                  width="320px"
                  height="290px"
                  alt=""
                />
              </Box.Image>
            </Box>
            <Box variant="light" number={2}>
              <Box.Title>{t("steps-uzbekistan.item-2.title")}</Box.Title>
              <Box.Body>{t("steps-uzbekistan.item-2.body")}</Box.Body>
              <Box.Image>
                <img
                  src={uzbekistanStep2}
                  width="320px"
                  height="290px"
                  alt=""
                />
              </Box.Image>
            </Box>
            <Box variant="light" number={3}>
              <Box.Title>{t("steps-uzbekistan.item-3.title")}</Box.Title>
              <Box.Body>{t("steps-uzbekistan.item-3.body")}</Box.Body>
              <Box.Image>
                <img
                  src={uzbekistanStep3}
                  width="320px"
                  height="290px"
                  alt=""
                />
              </Box.Image>
            </Box>
            <Box variant="light" number={4}>
              <Box.Title>{t("steps-uzbekistan.item-4.title")}</Box.Title>
              <Box.Body>{t("steps-uzbekistan.item-4.body")}</Box.Body>
              <Box.Image>
                <img
                  src={uzbekistanStep4}
                  width="320px"
                  height="291px"
                  alt=""
                />
              </Box.Image>
            </Box>
            <Box variant="light" number={5}>
              <Box.Body>{t("steps-uzbekistan.item-5.body")}</Box.Body>
              <Box.Image>
                <img
                  src={uzbekistanStep5}
                  width="320px"
                  height="291px"
                  alt=""
                />
              </Box.Image>
            </Box>
            <Box variant="light" number={6}>
              <Box.Title>{t("steps-uzbekistan.item-6.title")}</Box.Title>
              <Box.Body>{t("steps-uzbekistan.item-6.body")}</Box.Body>
              <Box.Image>
                <img
                  src={uzbekistanStep6}
                  width="186px"
                  height="355px"
                  alt=""
                />
              </Box.Image>
            </Box>
          </ScrollableCarousel>
        </Tab.Item.Body>
      </Tab.Item>
    </Tab>
  );
};
