import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useDialogContext } from "helpers/hooks";
import { PrimaryBlock } from "../PrimaryBlock";
import { IconSpeed, IconSupport } from "../../assets";
import { Button } from "../Button";
import { compliance, antifrod, api, bankCard, transfer } from "assets/img";

import sbp from "../../assets/prime/sbp.x1.5.png";
import sber from "../../assets/prime/sber.x1.5.png";
import aml from "../../assets/prime/aml.x1.5.png";

import styles from "./PrimaryBlockProcessing.module.scss";

export const PrimaryBlockProcessing: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setIsDialogOpened } = useDialogContext();

  function handleClick() {
    setIsDialogOpened(true);
  }

  return (
    <PrimaryBlock
      className={styles.primaryBlockProcessing}
      containerClassName={styles.primaryBlockContainerProcessing}
    >
      <PrimaryBlock.Title
        className={styles.animationOffsetY}
        style={{ transform: "translateY(30px)" }}
      >
        {t("processing.primary-block.title")}
      </PrimaryBlock.Title>
      <PrimaryBlock.Body
        className={styles.animationOffsetY}
        style={{ transform: "translateY(30px)" }}
      >
        {t("processing.primary-block.body")}
      </PrimaryBlock.Body>
      <PrimaryBlock.Info>
        <PrimaryBlock.Info.Item
          className={styles.animationOffsetY}
          icon={<IconSpeed />}
          style={{ transform: "translateY(30px)" }}
        >
          {t("processing.primary-block.info-1")}
        </PrimaryBlock.Info.Item>
        <PrimaryBlock.Info.Item
          className={styles.animationOffsetY}
          icon={<IconSupport />}
          style={{ transform: "translateY(10px)" }}
        >
          {t("processing.primary-block.info-2")}
        </PrimaryBlock.Info.Item>
      </PrimaryBlock.Info>
      <PrimaryBlock.Footer
        className={styles.animationOffsetY}
        style={{ transform: "translateY(40px)" }}
      >
        <Button
          variant="secondary"
          wide={true}
          as="button"
          onClick={handleClick}
        >
          {t("processing.primary-block.button")}
        </Button>
      </PrimaryBlock.Footer>
      <PrimaryBlock.Image>
        <img
          src={antifrod}
          className={cn(styles.animationOffsetY, styles.antifrod)}
          alt=""
        />
        <img
          src={sbp}
          className={cn(styles.animationOffsetY, styles.sbp)}
          alt=""
        />
        <img
          src={sber}
          className={cn(styles.animationOffsetY, styles.sber)}
          alt=""
        />
        <img
          src={api}
          className={cn(styles.animationOffsetY, styles.api)}
          alt=""
        />
        <img
          src={aml}
          className={cn(styles.animationOffsetY, styles.aml)}
          alt=""
        />
        <img
          src={bankCard}
          className={cn(styles.animationOffsetY, styles.bankCard)}
          alt=""
        />
        <img
          src={transfer}
          className={cn(styles.animationOffsetY, styles.transfer)}
          alt=""
        />
        <img
          src={compliance}
          className={cn(styles.animationOffsetY, styles.compliance)}
          alt=""
        />
      </PrimaryBlock.Image>
    </PrimaryBlock>
  );
};
