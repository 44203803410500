import React, { FunctionComponent } from "react";
import { Box } from "components";
import styles from "./Online.module.scss";

export type SendMoneyStepProps = {
  variant: "green" | "blue" | "cyan";
  imgSrc: string;
  width: string;
} & React.HTMLAttributes<HTMLDivElement>;

export interface ISendMoneyStep extends FunctionComponent<SendMoneyStepProps> {}

export const SendMoneyStep: ISendMoneyStep = ({ variant, imgSrc, width }) => {
  return (
    <Box variant={variant} className={styles.moneyStep}>
      <Box.Image className={styles.moneyStepImage}>
        <img
          src={imgSrc}
          alt=""
          style={{ "--width": width } as React.CSSProperties}
        />
      </Box.Image>
    </Box>
  );
};
