import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "../Box";
import { BoxProps } from "../Box/Box";
import { Button } from "../Button";
import { IconCircle } from "../Icons";
import globe from "../../assets/globe.svg";
import style from "./CountryTransferBox.module.scss";

export const CountryTransferBox: FunctionComponent<Partial<BoxProps>> = (
  props,
) => {
  const { t } = useTranslation();

  return (
    <Box position="top" {...props}>
      <Box.Image className={style.image}>
        <IconCircle variant="light" src={globe} />
      </Box.Image>
      <Box.Title>{t("home.country-transfer-box.title")}</Box.Title>

      <Box.Body>
        <p className="mb-30">
          Use our Xoom service to transfer money to over 130 countries, even if
          your recipient doesn’t have a PayPal or Xoom account.3
        </p>
        <Button to="/" variant="secondary" disabled={true}>
          {t("home.country-transfer-box.button")}
        </Button>
      </Box.Body>
    </Box>
  );
};
