import React, { ReactNode } from "react";
import cn from "classnames";
import styles from "./CheckBox.module.scss";

type CheckBoxProps = {
  label: ReactNode | string;
  error?: string;
  value: boolean;
  name: string;
  onChange?: () => void;
  onBlur?: () => void;
};

export const CheckBox: React.FC<CheckBoxProps> = ({
  label,
  value,
  onChange,
  onBlur,
  error,
  ...props
}) => {
  return (
    <label className={styles.checkbox}>
      <div className={styles.container}>
        <input
          className={cn(styles.field, error && styles.invalid)}
          type="checkbox"
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
          {...props}
        />
        <div className={styles.label}>
          <div className={styles.qwe}></div>
          <div className={styles.asd}>{label}</div>
        </div>
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </label>
  );
};
