import React, { FunctionComponent, useContext, useMemo, useRef } from "react";
import { Title, TitleInterface } from "./Title";
import { Body, BodyInterface } from "./Body";
import { ItemContext, ItemContextType } from "./ItemContext";
import { Context } from "./Context";
import PropTypes from "prop-types";

export type ItemProps = React.LiHTMLAttributes<HTMLLIElement>;

export interface ItemInterface extends FunctionComponent<ItemProps> {
  Title: TitleInterface;
  Body: BodyInterface;
}

export const Item: ItemInterface = ({ children, ...props }) => {
  const [current, setCurrent] = useContext(Context);
  const ref = useRef(null);
  const show = current === ref;
  const item: ItemContextType = useMemo(
    function () {
      return [
        show,
        () => setCurrent((prevCurrent) => (prevCurrent === ref ? null : ref)),
      ];
    },
    [show, setCurrent],
  );
  return <ItemContext.Provider value={item}>{children}</ItemContext.Provider>;
};
Item.propTypes = {
  children: PropTypes.arrayOf((parent, index) => {
    const item = parent[index];
    if (
      React.isValidElement(item) &&
      (item.type === Title || item.type === Body)
    ) {
      return null;
    }
    return new Error();
  }),
};
Item.displayName = "Accordion.Item";
Item.Title = Title;
Item.Body = Body;
