import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components";
import iconGreenOk from "assets/icon-green-ok.svg";
import styles from "./ConnectionRequestAccepted.module.scss";

interface ConnectionRequestAcceptedProps {
  onResetForm: () => void;
}

export const ConnectionRequestAccepted: React.FC<
  ConnectionRequestAcceptedProps
> = ({ onResetForm }) => {
  const { t } = useTranslation();

  function handleClickButton() {
    onResetForm();
  }

  return (
    <div className={styles.requestAccepted}>
      <img src={iconGreenOk} alt="" />

      <h3>{t("connection-request.sended-title")}</h3>
      <p>{t("connection-request.sended-desc")}</p>

      <Button
        as="button"
        type="button"
        variant="secondary"
        wide={true}
        onClick={handleClickButton}
      >
        {t("connection-request.ok")}
      </Button>
    </div>
  );
};
