import React, { PropsWithChildren } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useIsAuthenticated, useSignOut } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Logo, HomeTelegram } from "assets";
import styles from "./Header.module.scss";
import { Button } from "../Button";

type HeaderProps = {
  isMenuOpened?: boolean;
  onToggleMenu?: () => void;
};

export const Header = ({
  isMenuOpened,
  onToggleMenu,
  children,
}: PropsWithChildren<HeaderProps>) => {
  const isAuthenticated = useIsAuthenticated()();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const { t } = useTranslation();

  function handleSignOut() {
    signOut();
    navigate("/sign-in");
  }

  return (
    <div className={cn(styles.header, { [styles.active]: isMenuOpened })}>
      <NavLink to="/" className={styles.home}>
        <Logo className={styles.logo} />
      </NavLink>

      {children}

      <ul className={styles.contacts}>
        <li>
          <a href="mailto:info@3ds.money">info@3ds.money</a>
        </li>
        <li>
          <a className={styles.icon} href="https://t.me/+asJlcl_6wwljMTAy">
            <HomeTelegram />
          </a>
        </li>
      </ul>

      <div className={styles.buttons}>
        <Button
          className={styles.connect}
          as={Link}
          to="/sign-in"
          variant="secondary"
        >
          {t("global.join-now")}
        </Button>

        {!isAuthenticated && (
          <Button
            className={styles.login}
            as={Link}
            to="/sign-in"
            variant="primary"
          >
            {t("global.log-in")}
          </Button>
        )}

        {isAuthenticated && (
          <Button
            className={styles.login}
            as="button"
            variant="secondary"
            type="button"
            onClick={handleSignOut}
          >
            {t("global.log-out")}
          </Button>
        )}
      </div>

      <Button className={styles.menu} to="#" onClick={onToggleMenu}>
        {" "}
      </Button>
    </div>
  );
};
