import React, {
  FunctionComponent,
  MouseEventHandler,
  TransitionEventHandler,
  useContext,
  useRef,
  useState,
} from "react";
import { ItemContext } from "./ItemContext";
import styles from "./Accordion.module.scss";
import cn from "classnames";

export type TitleProps = {
  onToggle?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export interface TitleInterface extends FunctionComponent<TitleProps> {}

export const Title: TitleInterface = ({ children, onToggle, ...props }) => {
  const [show, toggle] = useContext(ItemContext);

  function handleClick() {
    toggle();

    onToggle && onToggle();
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions
    <dt
      {...props}
      className={cn(styles.accordionTitle, {
        [styles.accordionTitleShow]: show,
      })}
      onClick={handleClick}
    >
      {children}
    </dt>
  );
};
Title.displayName = "Accordion.Item.Title";
