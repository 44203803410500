import React, {
  FunctionComponent,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { Title, TitleProps } from "./Title";
import { Body, BodyProps } from "./Body";
import { Image, ImageProps } from "./Image";
import { ButtonsGroup, ButtonsGroupProps } from "./ButtonsGroup";
import styles from "./Box.module.scss";

export type BoxProps = {
  variant?: "dark" | "light" | "white" | "yellow" | "green" | "blue" | "cyan";
  position?: "right" | "top" | "bottom";
  centered?: boolean;
  number?: number;
  animated?: "none" | "scroll";
} & React.HTMLAttributes<HTMLDivElement>;

export interface BoxType extends FunctionComponent<BoxProps> {
  Title: FunctionComponent<TitleProps>;
  Body: FunctionComponent<BodyProps>;
  Image: FunctionComponent<ImageProps>;
  ButtonsGroup: FunctionComponent<ButtonsGroupProps>;
}

function findByType<P>(
  children: ReactNode | ReactNode[],
  type: JSXElementConstructor<P>,
): ReactElement<P> {
  return React.Children.toArray(children).find(function (node) {
    return React.isValidElement(node) && node.type == type;
  }) as ReactElement<P>;
}

export const Box: BoxType = ({
  className = null,
  variant,
  position,
  centered,
  children,
  number = null,
  animated = "none",
  ...props
}) => {
  const title = findByType(children, Title);
  const body = findByType(children, Body);
  const image = findByType(children, Image);
  const buttonsGroup = findByType(children, ButtonsGroup);

  const ref = useRef<HTMLDivElement>(null);
  const [transition, setTransition] = useState(false);

  const onScroll = useCallback(() => {
    if (!(ref.current && animated === "scroll")) {
      return;
    }
    setTransition(
      ref.current.offsetTop <
        window.scrollY + window.innerHeight - ref.current.offsetHeight,
    );
  }, [animated]);

  useEffect(() => {
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  return (
    <div
      {...props}
      ref={ref}
      className={cn(
        styles.box,
        {
          [styles.boxScrollable]: animated === "scroll",
          [styles.boxTransition]: transition,
          [styles.boxDark]: variant == "dark",
          [styles.boxLight]: variant == "light",
          [styles.boxWhite]: variant == "white",
          [styles.boxYellow]: variant == "yellow",
          [styles.boxGreen]: variant == "green",
          [styles.boxBlue]: variant == "blue",
          [styles.boxCyan]: variant == "cyan",
          [styles.boxRight]: position == "right",
          [styles.boxTop]: position == "top",
          [styles.boxBottom]: position == "bottom",
          [styles.contentCentered]: centered,
        },
        className,
      )}
    >
      {number !== null && <div className={styles.boxNumber}>{number}</div>}
      {(title || body || buttonsGroup) && (
        <div className={styles.boxWrap}>
          {title}
          {body}
          {buttonsGroup}
        </div>
      )}
      {image}
    </div>
  );
};
Box.displayName = "Box";
Box.Title = Title;
Box.Body = Body;
Box.Image = Image;
Box.ButtonsGroup = ButtonsGroup;
