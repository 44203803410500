import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { img4 } from "assets/img";
import { Box, Button } from "components";
import styles from "./OnlineTransfer.module.scss";

export const OnlineTransfer: FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      variant="light"
      centered={true}
      className={styles.onlineTransferBox}
      style={{ overflow: "hidden" }}
    >
      <Box.Title narrowed={true}>{t("home.online-transfer.title")}</Box.Title>
      <Box.Body>
        <p
          dangerouslySetInnerHTML={{ __html: t("home.online-transfer.body") }}
        />
      </Box.Body>
      <Box.ButtonsGroup>
        <Button to="/" variant="secondary" disabled={true}>
          {t("home.online-transfer.button-1")}
        </Button>
        <Button to="/online" rightIcon="arrow-right">
          {t("home.online-transfer.button-2")}
        </Button>
      </Box.ButtonsGroup>
      <Box.Image>
        <img
          className={styles.mobile}
          src={img4}
          alt=""
          width="263px"
          height="286px"
          style={{
            position: "absolute",
          }}
        />
        <div className={cn(styles.coinSprite, styles.coinSpriteKyrgyzstan)} />
        <div className={cn(styles.coinSprite, styles.coinSpriteUzbekistan)} />
        <div className={cn(styles.coinSprite, styles.coinSpriteArmenia)} />
        <div className={cn(styles.coinSprite, styles.coinSpriteKazakhstan)} />
        <div className={cn(styles.coinSprite, styles.coinSpriteTajikistan)} />
      </Box.Image>
    </Box>
  );
};
