import { createContext } from "react";

type DialogContextType = {
  isDialogOpened: boolean;
  setIsDialogOpened: (value: boolean) => void;
};

export const DialogContext = createContext<DialogContextType>({
  isDialogOpened: false,
  setIsDialogOpened: () => {},
});
