import React, {
  FunctionComponent,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useState,
} from "react";
import cn from "classnames";
import { Item, ItemInterface } from "./Item";
import { Context } from "./Context";
import PropTypes from "prop-types";
import styles from "./Tab.module.scss";

export type TabProps = React.HTMLAttributes<HTMLDListElement> & {
  counter?: boolean;
};

export interface TabInterface extends FunctionComponent<TabProps> {
  Item: ItemInterface;
}

function findByType<P>(
  children: ReactNode | ReactNode[],
  type: JSXElementConstructor<P>,
): ReactElement<P> {
  return React.Children.toArray(children).find(function (node) {
    return React.isValidElement(node) && node.type == type;
  }) as ReactElement<P>;
}

function findChildByType<P extends { tabItemIndex?: number }>(
  children: ReactNode | ReactNode[],
  type: JSXElementConstructor<P>,
): ReactElement<P>[] {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return React.Children.map(children, (node, index) => {
    if (React.isValidElement(node)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return React.cloneElement(findByType(node.props.children, type), {
        tabItemIndex: index,
      });
    } else {
      throw new Error();
    }
  }) as ReactElement<P>[];
}

export const Tab: TabInterface = ({
  counter,
  children,
  className,
  ...props
}) => {
  const state = useState<number>(0);
  const titleList = findChildByType(children, Item.Title);
  const bodyList = findChildByType(children, Item.Body);
  return (
    <Context.Provider value={state}>
      <div className={cn(styles.tab, className)}>
        <div className={styles.tabNavigations}>{titleList}</div>
        <div className={styles.tabContent}>{bodyList}</div>
      </div>
    </Context.Provider>
  );
};
Tab.Item = Item;
Tab.displayName = "Tab";
Tab.propTypes = {
  children: PropTypes.arrayOf((parent, index) => {
    const item = parent[index];
    if (React.isValidElement(item) && item.type === Item) {
      return null;
    }
    return new Error();
  }),
};
