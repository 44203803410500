import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Box } from "components";
import { BoxProps } from "components/Box/Box";

export const FAQBox: FunctionComponent<Partial<BoxProps>> = (props) => {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <Box.Body>
        <Accordion>
          <Accordion.Item>
            <Accordion.Item.Title>{t("faq.question-1")}</Accordion.Item.Title>
            <Accordion.Item.Body>{t("faq.answer-1")}</Accordion.Item.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Item.Title>{t("faq.question-2")}</Accordion.Item.Title>
            <Accordion.Item.Body>{t("faq.answer-2")}</Accordion.Item.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Item.Title>{t("faq.question-3")}</Accordion.Item.Title>
            <Accordion.Item.Body>{t("faq.answer-3")}</Accordion.Item.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Item.Title>{t("faq.question-4")}</Accordion.Item.Title>
            <Accordion.Item.Body>{t("faq.answer-4")}</Accordion.Item.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Item.Title>{t("faq.question-5")}</Accordion.Item.Title>
            <Accordion.Item.Body>{t("faq.answer-5")}</Accordion.Item.Body>
          </Accordion.Item>
        </Accordion>
      </Box.Body>
    </Box>
  );
};
