import React, { FunctionComponent } from "react";
import styles from "./PrimaryBlock.module.scss";
import cn from "classnames";

type PrimaryBlockProps = React.HTMLAttributes<HTMLDivElement>;

export interface FooterInterface extends FunctionComponent<PrimaryBlockProps> {}

export const Footer: FooterInterface = ({ className = null, ...props }) => (
  <div className={cn(styles.primaryBlockFooter, className)} {...props} />
);
Footer.displayName = "PrimaryBlock.Footer";
