import React, { forwardRef } from "react";
import cn from "classnames";
import styles from "./Box.module.scss";

export interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  narrowed?: boolean;
}

export const Title = forwardRef<HTMLHeadingElement, TitleProps>(function Title(
  { className = null, children, narrowed, ...props },
  ref,
) {
  return (
    <h3
      {...props}
      className={cn(
        styles.boxTitle,
        { [styles.boxTitleNarrowed]: narrowed },
        className,
      )}
      ref={ref}
    >
      {children}
    </h3>
  );
});

Title.displayName = "Box.Title";
