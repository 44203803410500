import React, { FunctionComponent, useMemo, useRef, useState } from "react";
import cn from "classnames";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import {
  OverlayScrollbarsComponentProps,
  OverlayScrollbarsComponentRef,
} from "overlayscrollbars-react/types/OverlayScrollbarsComponent";
import "overlayscrollbars/styles/overlayscrollbars.css";
import styles from "./ScrollableCarousel.module.scss";
import { OverlayScrollbars } from "overlayscrollbars";
import { ArrowLeft, ArrowRight } from "../../assets";

export type ScrollableCarouselProps = React.HTMLAttributes<HTMLDivElement>;

type OffsetType = { left: number; right: number };

function getOffsets(parent: HTMLElement): OffsetType[] {
  const children = parent.children;
  const list: OffsetType[] = [];
  for (let i = 0; i < children.length; i++) {
    const item = children.item(i) as HTMLElement;
    if (item?.parentElement === parent) {
      list.push({
        left: item.offsetLeft,
        right: item.offsetLeft + item.offsetWidth,
      });
    }
  }
  list.sort((left, right) => left.left - right.left);
  return list;
}

const options: OverlayScrollbarsComponentProps["options"] = {
  scrollbars: {
    theme: styles.osThemeCarousel,
    autoHide: "never",
  },
  overflow: {
    x: "scroll",
    y: "visible",
  },
};

export const ScrollableCarousel: FunctionComponent<ScrollableCarouselProps> = ({
  className = null,
  ...props
}) => {
  const ref = useRef<OverlayScrollbarsComponentRef>(null);
  const [disabled, setDisabled] = useState({
    prev: true,
    next: true,
  });
  function scrollTo(index: number) {
    const instance = ref.current?.osInstance();
    if (!instance) {
      return;
    }

    const { scrollOffsetElement } = instance.elements();
    const { scrollLeft, offsetWidth } = scrollOffsetElement;
    const expectedLeft = scrollLeft + index * offsetWidth;
    const expectedRight = expectedLeft + offsetWidth;
    const offsets = getOffsets(scrollOffsetElement).filter(
      (item) => item.left <= expectedRight && expectedLeft <= item.right,
    );

    if (offsets[0]) {
      scrollOffsetElement.scrollTo({
        behavior: "smooth",
        left: offsets[0].left,
      });
    }
  }
  const events = useMemo(function () {
    function onScroll(instance: OverlayScrollbars) {
      const {
        scrollOffsetElement: { scrollLeft, offsetWidth, scrollWidth },
      } = instance.elements();
      const prev = scrollLeft <= 0;
      const next = scrollLeft + offsetWidth >= scrollWidth;
      setDisabled((prevState) =>
        prevState.prev === prev && prevState.next === next
          ? prevState
          : { prev, next },
      );
    }
    return {
      scroll: onScroll,
      updated: onScroll,
      initialized: onScroll,
    };
  }, []);
  return (
    <>
      <OverlayScrollbarsComponent
        ref={ref}
        {...props}
        className={cn(styles.scrollableCarousel, className)}
        events={events}
        options={options}
      />
      <div className={styles.scrollableCarouselArrows}>
        <button
          type="button"
          className={styles.scrollableCarouselArrowButton}
          disabled={disabled.prev}
          onClick={() => scrollTo(-1)}
        >
          <ArrowLeft />
        </button>
        <button
          type="button"
          className={styles.scrollableCarouselArrowButton}
          disabled={disabled.next}
          onClick={() => scrollTo(+1)}
        >
          <ArrowRight />
        </button>
      </div>
    </>
  );
};
