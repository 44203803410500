import React from "react";
import cn from "classnames";
import styles from "./Box.module.scss";

export type ButtonsGroupProps = React.HTMLAttributes<HTMLDivElement>;

export const ButtonsGroup: React.FC<ButtonsGroupProps> = ({
  className = null,
  ...props
}) => {
  return <div {...props} className={cn(styles.boxButtonsGroup, className)} />;
};
ButtonsGroup.displayName = "Box.ButtonsGroup";
