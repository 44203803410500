import React, {
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Box } from "components";
import { BoxProps } from "components/Box/Box";
import homeTelegram from "assets/home-telegram.svg";
import homeSupport from "assets/home-support.x1.5.png";
import styles from "./TechSupport.module.scss";

export const TechSupport: FunctionComponent<Partial<BoxProps>> = (props) => {
  const { t } = useTranslation();
  const headerRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  const [offset, setOffset] = useState<string>("");

  const onResize = useCallback(() => {
    if (!headerRef.current || !textRef.current) {
      return "0px";
    }

    const containerTopPadding = 30;
    const headerBottomMargin = 20;
    const textBottomMargin = 20;
    const { height: headerHeight } = headerRef.current.getBoundingClientRect();
    const { height: textHeight } = textRef.current.getBoundingClientRect();

    setOffset(
      `${containerTopPadding + headerHeight + headerBottomMargin + textHeight + textBottomMargin}px`,
    );
  }, []);

  useEffect(() => {
    onResize();

    window.removeEventListener("resize", onResize);
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  return (
    <Box
      style={{ "--vdd": offset } as CSSProperties}
      className={styles.box}
      {...props}
    >
      {/* @ts-expect-error: fix later */}
      <Box.Title ref={headerRef}>{t("home.tech-support.title")}</Box.Title>

      <Box.Body>
        <p
          ref={textRef}
          className={styles.info}
          dangerouslySetInnerHTML={{ __html: t("home.tech-support.body") }}
        />

        <div className={styles.items}>
          <div>
            <p className="small gray">{t("home.tech-support.email")}</p>
            <h4>
              <a href="mailto:info@3ds.money">info@3ds.money</a>
            </h4>
          </div>

          <div>
            <p className="small gray">{t("home.tech-support.telegram")}</p>
            <p className={styles.icon}>
              <a href="https://t.me/+asJlcl_6wwljMTAy">
                <img src={homeTelegram} alt="" />
              </a>
            </p>
          </div>
        </div>
      </Box.Body>
      <Box.Image>
        <img src={homeSupport} alt="" width="241px" height="275px" />
      </Box.Image>
    </Box>
  );
};
