import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import agreementDoneBig from "../../../assets/prime/agreement-done-big.x1.5.png";
import agreementDoneMiddle from "../../../assets/prime/agreement-done-middle.x1.5.png";
import agreementDoneSmall from "../../../assets/prime/agreement-done-small.x1.5.png";
import agreementCheck from "../../../assets/prime/agreement-check.x1.5.png";
import agreementBg from "../../../assets/prime/agreement-bg.svg";
import { Box } from "components";
import styles from "./AgreementBox.module.scss";

export const AgreementBox: FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      variant="dark"
      position="right"
      animated="scroll"
      className={styles.agreementBox}
    >
      <Box.Title>{t("processing.agreement.title")}</Box.Title>
      <Box.Body>
        <ul>
          <li>{t("processing.agreement.body-1")}</li>
          <li>{t("processing.agreement.body-2")}</li>
          <li>{t("processing.agreement.title")}</li>
        </ul>
      </Box.Body>
      <Box.Image>
        <img
          src={agreementBg}
          alt=""
          width="300px"
          height="300px"
          className={styles.agreementBg}
        />
        <img
          src={agreementDoneMiddle}
          alt=""
          width="118px"
          height="120px"
          className={styles.agreementDoneMiddle}
        />
        <img
          src={agreementDoneSmall}
          alt=""
          width="77px"
          height="78px"
          className={styles.agreementDoneSmall}
        />
        <img
          src={agreementCheck}
          alt=""
          width="50px"
          height="39px"
          className={styles.agreementCheck}
        />
        <img
          src={agreementDoneBig}
          alt=""
          width="154px"
          height="158px"
          className={styles.agreementDoneBig}
        />
      </Box.Image>
    </Box>
  );
};
