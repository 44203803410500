import * as Yup from "yup";
import "yup-phone-lite";
import { useTranslation } from "react-i18next";

export const useValidators = () => {
  const { t } = useTranslation();

  const nameValidator = Yup.string()
    .matches(/^[a-zA-Zа-яА-Я ,.'-]{2,40}$/, t("validation.valid-name"))
    .required(t("validation.required-name"));

  const emailValidator = Yup.string()
    .email(t("validation.valid-email"))
    .required(t("validation.required-email"));

  const passwordValidator = Yup.string().required(t("validation.password"));

  const passwordConfirmValidator = Yup.string()
    .oneOf([Yup.ref("password"), ""], t("validation.valid-password-confirm"))
    .required(t("validation.required-password-confirm"));

  const phoneValidator = Yup.string()
    .phone("RU", t("validation.valid-phone"))
    .required(t("validation.required-phone"));

  const companyValidator = Yup.string().required(
    t("validation.required-company"),
  );

  const siteValidator = Yup.string().required(t("validation.required-site"));

  const agreementValidator = Yup.boolean().oneOf(
    [true],
    t("validation.required-agreement"),
  );

  const loginValidator = Yup.string().required(t("validation.required-login"));

  const typeValidator = Yup.string()
    .matches(/^(e-commerce|retail)$/, t("validation.valid-payment-type"))
    .required(t("validation.required-payment-type"));

  const codeValidator = Yup.string().required(t("validation.required-code"));

  const citizenshipValidator = Yup.string().required(
    t("validation.required-citizenship"),
  );

  return {
    nameValidator,
    emailValidator,
    passwordValidator,
    passwordConfirmValidator,
    agreementValidator,
    codeValidator,
    phoneValidator,
    citizenshipValidator,
    companyValidator,
    siteValidator,
    loginValidator,
    typeValidator,
  };
};
