import React, { FunctionComponent } from "react";
import styles from "./PrimaryBlock.module.scss";
import cn from "classnames";
import { InfoItem, InfoItemInterface } from "./InfoItem";

type PrimaryBlockProps = React.HTMLAttributes<HTMLDivElement>;

export interface InfoInterface extends FunctionComponent<PrimaryBlockProps> {
  Item: InfoItemInterface;
}

export const Info: InfoInterface = ({ className = null, ...props }) => (
  <div className={cn(styles.primaryBlockInfo, className)} {...props} />
);
Info.displayName = "PrimaryBlock.Info";
Info.Item = InfoItem;
