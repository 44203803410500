import React from "react";
import { Form, useFormikContext } from "formik";
import type { FormikValues } from "formik";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Input, InputPassword } from "components";
import styles from "./SignInForm.module.scss";

type SignInFormProps = {
  formError: string;
};

export const SignInForm: React.FC<SignInFormProps> = ({ formError }) => {
  const { t } = useTranslation();

  const { values, touched, errors, handleChange, handleBlur, isSubmitting } =
    useFormikContext() as FormikValues;

  return (
    <Form>
      {formError && (
        <div className={styles.formError}>
          <p>{formError}</p>
        </div>
      )}

      <Input
        label={t("sign-in.login")}
        name="login"
        value={values.login}
        error={touched.login && errors.login}
        onChange={handleChange}
        onBlur={handleBlur}
        containerClassName={styles.login}
      />

      <InputPassword
        label={t("sign-in.password")}
        name="password"
        value={values.password}
        error={touched.password && errors.password}
        onChange={handleChange}
        onBlur={handleBlur}
        containerClassName={styles.password}
      />

      <p className={styles.reset}>
        <NavLink to="/reset-password">{t("sign-in.forgot-password")}</NavLink>
      </p>

      <Button as="button" type="submit" variant="primary" wide={true}>
        {t("sign-in.enter")}
      </Button>
    </Form>
  );
};
