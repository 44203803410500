import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "components";
import styles from "./CommissionBox.module.scss";

export const CommissionBox: FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      variant="light"
      position="right"
      animated="scroll"
      className={styles.commissionBox}
    >
      <Box.Title
        dangerouslySetInnerHTML={{
          __html: t("processing.commission.title"),
        }}
      />
      <Box.Body
        dangerouslySetInnerHTML={{
          __html: t("processing.commission.body"),
        }}
      />
      <Box.Image>
        <div className={styles.commissionSpriteZero} />
        <div className={styles.commissionSpriteZeroSmall} />
        <div className={styles.commissionSpritePercent} />
      </Box.Image>
    </Box>
  );
};
