import React from "react";
import { Radio, RadioType } from "components/Radio/Radio";
import styles from "./RadioGroup.module.scss";

type RadioGroupProps = {
  title: string;
  name: string;
  options: RadioType[];
  value?: RadioType["value"];
  error?: string;
  onChange?: () => void;
  onBlur?: () => void;
};

export const RadioGroup = (props: RadioGroupProps) => {
  const {
    title,
    name,
    options,
    value: selectedValue,
    error,
    onChange,
    onBlur,
  } = props;

  const handleBlur = () => onBlur?.();

  return (
    <div className={styles.group}>
      <p className={styles.title}>{title}</p>

      <div className={styles.inputs}>
        {options.map(({ label, value }) => (
          <Radio
            key={value}
            groupName={name}
            label={label}
            value={value}
            selected={selectedValue || ""}
            onChange={onChange}
            onBlur={handleBlur}
            hasError={!!error}
          />
        ))}
      </div>

      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};
