import React, { ReactNode, HTMLAttributes, useMemo } from "react";
import i18n from "i18next";
import cn from "classnames";
import styles from "./Select.module.scss";

type SelectOptionType = {
  title?: string;
  ru?: string;
  en?: string;
  value: string;
  isDisabled: boolean;
};

interface SelectPropsType extends HTMLAttributes<HTMLSelectElement> {
  name: string;
  label: string;
  value: string;
  options: SelectOptionType[];
  info?: ReactNode | string;
  error: string;
}

export const Select: React.FC<SelectPropsType> = ({
  label,
  value,
  options,
  error,
  info,
  ...props
}) => {
  const items = useMemo(() => {
    return options.map((option) => {
      const title =
        option.title || option[i18n.language as keyof SelectOptionType];

      return {
        ...option,
        title,
      };
    });
  }, [options]);

  return (
    <label className={styles.select}>
      <div className={cn(styles.container, !!value && styles.filled)}>
        <select
          className={cn(styles.field, error && styles.invalid)}
          defaultValue={value}
          {...props}
        >
          {items.map((item) => (
            <option key={item.value} value={item.value}>
              {item.title}
            </option>
          ))}
        </select>
        <span className={styles.label}>{label}</span>
      </div>

      {info && <div className={styles.info}>{info}</div>}
      {error && <div className={styles.error}>{error}</div>}
    </label>
  );
};
