import React from "react";
import cn from "classnames";
import styles from "./Box.module.scss";

export type BodyProps = React.HTMLAttributes<HTMLDivElement>;

export const Body: React.FC<BodyProps> = ({ className = null, ...props }) => {
  return <div {...props} className={cn(styles.boxContent, className)} />;
};
Body.displayName = "Box.Body";
