import React, { HTMLAttributes } from "react";
import styles from "./InputGroup.module.scss";

interface InputGroupProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
}

export const InputGroup: React.FC<InputGroupProps> = ({
  title,
  children,
  ...props
}) => {
  return (
    <div className={styles.group} {...props}>
      <p className={styles.title}>{title}</p>
      <div className={styles.inputs}>{children}</div>
    </div>
  );
};
