import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  Box,
  Container,
  CountryBox,
  CountryTransferBox,
  StepByStepTab,
  FAQBox,
  PrimaryBlockOnline,
} from "components";
import { simpleSend, sendingMethod, sources } from "assets/img";
import styles from "./Online.module.scss";
import {
  IconBlueSpeed,
  IconBlueSafe,
  IconBlueComission,
  IconBlueComfort,
} from "assets";
import { SendMoneyStep } from "./SendMoneyStep";

export const Online: React.FC = () => {
  const { t } = useTranslation();
  const [activeSendMoneyStep, setActiveSendMoneyStep] = useState("first");

  function handle(step: string) {
    setActiveSendMoneyStep(step);
  }

  return (
    <main>
      <PrimaryBlockOnline />

      <div className={styles.features}>
        <Container centered={true}>
          <ul className={styles.featuresItem}>
            <li>
              <IconBlueSpeed />
              <h5>{t("online.features.title-1")}</h5>
              <p>{t("online.features.body-1")}</p>
            </li>
            <li>
              <IconBlueSafe />
              <h5>{t("online.features.title-2")}</h5>
              <p>{t("online.features.body-2")}</p>
            </li>
            <li>
              <IconBlueComission />
              <h5>{t("online.features.title-3")}</h5>
              <p>{t("online.features.body-3")}</p>
            </li>
            <li>
              <IconBlueComfort />
              <h5>{t("online.features.title-4")}</h5>
              <p>{t("online.features.body-4")}</p>
            </li>
          </ul>
        </Container>
      </div>

      <Container centered={true}>
        <h2 className={styles.sendMoneyTitle}>
          {t("online.send-money.title")}
        </h2>
      </Container>

      <Container className={styles.moneySteps}>
        {activeSendMoneyStep === "first" && (
          <SendMoneyStep variant="green" imgSrc={simpleSend} width="290px" />
        )}

        {activeSendMoneyStep === "second" && (
          <SendMoneyStep variant="blue" imgSrc={sendingMethod} width="360px" />
        )}

        {activeSendMoneyStep === "third" && (
          <SendMoneyStep variant="cyan" imgSrc={sources} width="360px" />
        )}

        <Box variant="white" style={{ gridColumn: "span 18" }}>
          <Box.Title className={styles.sendMoneySubtitle}>
            {t("online.send-money.subtitle")}
          </Box.Title>
          <Box.Body className={styles.sendMoneyBox}>
            <Accordion counter>
              <Accordion.Item>
                <Accordion.Item.Title onToggle={() => handle("first")}>
                  {t("online.send-money.title-1")}
                </Accordion.Item.Title>
                <Accordion.Item.Body>
                  <SendMoneyStep
                    variant="green"
                    imgSrc={simpleSend}
                    width="290px"
                  />
                  <p className={styles.content}>
                    {t("online.send-money.body-1")}
                  </p>
                </Accordion.Item.Body>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Item.Title onToggle={() => handle("second")}>
                  {t("online.send-money.title-2")}
                </Accordion.Item.Title>
                <Accordion.Item.Body>
                  <SendMoneyStep
                    variant="blue"
                    imgSrc={sendingMethod}
                    width="360px"
                  />
                  <p className={styles.content}>
                    {t("online.send-money.body-2")}
                  </p>
                </Accordion.Item.Body>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Item.Title onToggle={() => handle("third")}>
                  {t("online.send-money.title-3")}
                </Accordion.Item.Title>
                <Accordion.Item.Body>
                  <SendMoneyStep
                    variant="cyan"
                    imgSrc={sources}
                    width="360px"
                  />
                  <p
                    className={styles.content}
                    dangerouslySetInnerHTML={{
                      __html: t("online.send-money.body-3"),
                    }}
                  />
                </Accordion.Item.Body>
              </Accordion.Item>
            </Accordion>
          </Box.Body>
        </Box>
      </Container>

      <Container centered={true} columned={true} style={{ gap: "10px" }}>
        <h2 className="mb-0">{t("home.join-now-title")}</h2>
        <h5 className="mb-30 ta-c">{t("home.join-now-subtitle")}</h5>
      </Container>

      <Container className={styles.countries}>
        <CountryBox variant="light" className={styles.countryBox} />
        <CountryTransferBox
          variant="yellow"
          position="top"
          className={styles.countryTransferBox}
        />
      </Container>

      <Container centered={true}>
        <h2>{t("online.samples")}</h2>
      </Container>

      <Container className={styles.steps}>
        <StepByStepTab />
      </Container>

      <Container className={styles.faq}>
        <h2>{t("online.faqs")}</h2>
        <FAQBox variant="white" className={styles.faqBox} />
      </Container>
    </main>
  );
};
