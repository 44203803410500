import React from "react";
import styles from "./Icon.module.scss";
import cn from "classnames";

export const IconCircle: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    variant: "light" | "dark";
    src?: string;
  }
> = ({ src, variant, style, children }) => {
  return (
    <div
      className={cn(styles.iconCircle, {
        [styles.iconCircleLight]: variant == "light",
        [styles.iconCircleDark]: variant == "dark",
      })}
      style={style}
    >
      {src ? <img src={src} alt="" width="30px" height="30px" /> : children}
    </div>
  );
};
