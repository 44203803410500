import React, { HTMLAttributes, ReactNode, useState } from "react";
import { Input } from "components";
import iconPasswordClosed from "assets/icon-password-closed.svg";
import iconPasswordShowed from "assets/icon-password-showed.svg";
import { StrengthMeter } from "./StrengthMeter";

interface InputPasswordProps extends HTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  info?: string;
  mask?: string;
  value?: string;
  name?: string;
  type?: string;
  inputmode?: string;
  containerClassName?: string;
  rightButton?: ReactNode;
  hasStrengthMeter?: boolean;
  onClickRightButton?: () => void;
}

export const InputPassword: React.FC<InputPasswordProps> = ({
  hasStrengthMeter,
  ...props
}) => {
  const [isPasswordShowed, setIsPasswordShowed] = useState(false);

  return (
    <Input
      type={isPasswordShowed ? "text" : "password"}
      info={hasStrengthMeter ? <StrengthMeter value={props.value} /> : null}
      rightButton={
        <img
          src={isPasswordShowed ? iconPasswordShowed : iconPasswordClosed}
          alt=""
        />
      }
      onClickRightButton={() => setIsPasswordShowed((prev) => (prev = !prev))}
      {...props}
    />
  );
};
