import React from "react";
import { Form, useFormikContext } from "formik";
import type { FormikValues } from "formik";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Input, InputPassword, Select, CheckBox } from "components";
import countries from "./countries.json";
import styles from "./SignUp.module.scss";

type RegistrationFormProps = {
  formError: string;
};

export const RegistrationForm: React.FC<RegistrationFormProps> = ({
  formError,
}) => {
  const { t } = useTranslation();
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext() as FormikValues;

  return (
    <Form>
      <h3 className={styles.title}>{t("sign-up.title")}</h3>
      <p className={styles.subtitle}>
        {t("sign-up.subtitle")}{" "}
        <NavLink to="/sign-in">{t("sign-up.login")}</NavLink>
      </p>

      {formError && (
        <div className={styles.formError}>
          <p>{formError}</p>
        </div>
      )}

      <Input
        label={t("sign-up.name")}
        name="name"
        value={values.name}
        error={touched.name && errors.name}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        label={t("sign-up.email")}
        name="email"
        inputMode="email"
        value={values.email}
        error={touched.email && errors.email}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Input
        label={t("sign-up.phone")}
        name="phone"
        inputMode="tel"
        value={values.phone}
        error={touched.phone && errors.phone}
        onChange={handleChange}
        onBlur={handleBlur}
        mask="+7(999) 999-99-99"
      />

      <Select
        label={t("sign-up.citizenship")}
        name="citizenship"
        options={countries}
        value={values.citizenship}
        error={touched.citizenship && errors.citizenship}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <InputPassword
        label={t("sign-up.password")}
        name="password"
        value={values.password}
        error={touched.password && errors.password}
        onChange={handleChange}
        onBlur={handleBlur}
        hasStrengthMeter={true}
      />

      <InputPassword
        label={t("sign-up.password-confirm")}
        name="passwordConfirm"
        value={values.passwordConfirm}
        error={touched.passwordConfirm && errors.passwordConfirm}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <CheckBox
        name="isAgree"
        value={values.isAgree}
        error={touched.isAgree && errors.isAgree}
        onChange={handleChange}
        onBlur={handleBlur}
        label={
          <span
            dangerouslySetInnerHTML={{ __html: t("sign-up.are-you-agree") }}
          />
        }
      />

      <Button as="button" type="submit" variant="primary" wide={true}>
        {t("sign-up.create-account")}
      </Button>
    </Form>
  );
};
