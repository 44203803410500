import React, { PropsWithChildren, useRef } from "react";
import { useLocation, useOutlet } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { HeaderLight } from "components";
import styles from "./LayoutLight.module.scss";

export const LayoutLight: React.FC<PropsWithChildren> = ({
  children = null,
}) => {
  const location = useLocation();

  children = useOutlet();

  const ref = useRef<HTMLElement>();

  return (
    <>
      <HeaderLight />

      <TransitionGroup component={null}>
        <CSSTransition
          key={location.pathname}
          classNames={{
            enter: styles.switchEnter,
            enterActive: styles.switchEnterActive,
            enterDone: styles.switchEnterDone,
            exit: styles.switchExit,
            exitActive: styles.switchExitActive,
            exitDone: styles.switchExitDone,
            appear: styles.switchAppear,
            appearActive: styles.switchAppearActive,
            appearDone: styles.switchAppearDone,
          }}
          timeout={240}
          nodeRef={ref}
        >
          <article>{children}</article>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};
