import { createBrowserRouter } from "react-router-dom";
import { Layout, LayoutLight } from "../layouts";
import {
  Home,
  Online,
  Processing,
  SignIn,
  SignUp,
  ResetPassword,
  ChangePassword,
} from "../pages";

const router = createBrowserRouter(
  [
    {
      Component: Layout,
      children: [
        {
          path: "",
          Component: Home,
        },
        {
          path: "online",
          Component: Online,
        },
        {
          path: "processing",
          Component: Processing,
        },
      ],
    },
    {
      Component: LayoutLight,
      children: [
        {
          path: "sign-in",
          Component: SignIn,
        },
        {
          path: "sign-up",
          Component: SignUp,
        },
        {
          path: "reset-password",
          Component: ResetPassword,
        },
        {
          path: "change-password",
          Component: ChangePassword,
        },
      ],
    },
  ],
  {
    // basename: "/landing"
  },
);
export default router;
