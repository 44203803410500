import React from "react";
import { useTranslation } from "react-i18next";
import { Form, useFormikContext } from "formik";
import type { FormikValues } from "formik";
import { Button, Input } from "components";
import styles from "./ResetPasswordForm.module.scss";

type ResetPasswordFormProps = {
  formError: string;
};

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  formError,
}) => {
  const { t } = useTranslation();
  const { values, touched, errors, handleChange, handleBlur, isSubmitting } =
    useFormikContext() as FormikValues;

  return (
    <Form>
      {formError && (
        <div className={styles.formError}>
          <p>{formError}</p>
        </div>
      )}

      <Input
        label={t("reset-password.email")}
        name="login"
        value={values.login}
        error={touched.login && errors.login}
        onChange={handleChange}
        onBlur={handleBlur}
        containerClassName={styles.login}
      />

      <Button as="button" type="submit" variant="primary" wide={true}>
        {t("reset-password.reset")}
      </Button>
    </Form>
  );
};
