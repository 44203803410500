import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useDialogContext } from "helpers/hooks";
import { PrimaryBlock } from "../PrimaryBlock";
import { Button } from "../Button";
import { antifrod, api, aml, compliance, support, interfaceCommon, control, done } from "assets/img";

import styles from "./PrimaryBlockHome.module.scss";

export const PrimaryBlockHome: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setIsDialogOpened } = useDialogContext();

  function handleClick() {
    setIsDialogOpened(true);
  }

  return (
    <PrimaryBlock
      className={styles.primaryBlockHome}
      containerClassName={styles.primaryBlockContainerHome}
    >
      <PrimaryBlock.Title
        className={styles.animationOffsetY}
        style={{ transform: "translateY(50px)" }}
      >
        {t("home.primary-block.header")}
      </PrimaryBlock.Title>
      <PrimaryBlock.Body
        className={styles.animationOffsetY}
        style={{ transform: "translateY(20px)" }}
      >
        {t("home.primary-block.body")}
      </PrimaryBlock.Body>
      <PrimaryBlock.Footer
        className={styles.animationOffsetY}
        style={{ transform: "translateY(40px)" }}
      >
        <Button
          variant="secondary"
          wide={true}
          as="button"
          onClick={handleClick}
        >
          {t("home.primary-block.button-1")}
        </Button>
        <Button to="/processing" rightIcon="arrow-right">
          {t("home.primary-block.button-2")}
        </Button>
      </PrimaryBlock.Footer>
      <PrimaryBlock.Image>
        <img
          src={support}
          className={cn(styles.animationOffsetY, styles.support)}
          alt=""
        />
        <img
          src={interfaceCommon}
          className={cn(styles.animationOffsetY, styles.interfaceCommon)}
          alt=""
        />
        <img
          src={antifrod}
          className={cn(styles.animationOffsetY, styles.antifrod)}
          alt=""
        />
        <img
          src={control}
          className={cn(styles.animationOffsetY, styles.control)}
          alt=""
        />
        <img
          src={compliance}
          className={cn(styles.animationOffsetY, styles.compliance)}
          alt=""
        />
        <img
          src={done}
          className={cn(styles.animationOffsetY, styles.done)}
          alt=""
        />
        <img
          src={api}
          className={cn(styles.animationOffsetY, styles.api)}
          alt=""
        />
        <img
          src={aml}
          className={cn(styles.animationOffsetY, styles.aml)}
          alt=""
        />
      </PrimaryBlock.Image>
    </PrimaryBlock>
  );
};
