import React, { FunctionComponent, useContext } from "react";
import styles from "./Tab.module.scss";
import cn from "classnames";
import { Context } from "./Context";

export type BodyProps = React.HTMLAttributes<HTMLElement> & {
  tabItemIndex?: number;
};

export interface BodyInterface extends FunctionComponent<BodyProps> {}

export const Body: BodyInterface = ({ tabItemIndex, children, ...props }) => {
  const [currentTabItemIndex] = useContext(Context);
  return (
    <div
      {...props}
      className={cn(styles.tabDescription, {
        [styles.tabDescriptionShow]: tabItemIndex === currentTabItemIndex,
      })}
    >
      {children}
    </div>
  );
};
Body.displayName = "Tab.Item.Body";
