import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useDialogContext } from "helpers/hooks";
import { api, antifrodRound, complianceDark } from "assets/img";

import sbpDark from "../../../assets/prime/sbp-dark.x1.5.png";
import tinkoff from "../../../assets/prime/tinkoff.x1.5.png";
import { Box, Button } from "../../../components";
import styles from "./PreProcessing.module.scss";

export const PreProcessing: FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  const { setIsDialogOpened } = useDialogContext();
  const { t } = useTranslation();

  function handleClick() {
    setIsDialogOpened(true);
  }

  return (
    <Box variant="dark" centered={true} className={styles.preProcessingBox}>
      <Box.Title narrowed={true}>{t("home.pre-processing.title")}</Box.Title>
      <Box.Body>
        <p className={styles.preProcessingText}>
          {t("home.pre-processing.body")}
        </p>
      </Box.Body>
      <Box.ButtonsGroup>
        <Button variant="light" as="button" onClick={handleClick}>
          {t("home.pre-processing.button-1")}
        </Button>
        <Button to="/processing" rightIcon="arrow-right">
          {t("home.pre-processing.button-2")}
        </Button>
      </Box.ButtonsGroup>
      <Box.Image>
        <img
          src={api}
          alt=""
          width="126px"
          height="128px"
          className={styles.api}
        />
        <img
          src={antifrodRound}
          alt=""
          width="177px"
          height="154px"
          className={styles.antifrodRound}
        />
        <img
          src={tinkoff}
          alt=""
          width="92px"
          height="94px"
          className={styles.tinkoff}
        />
        <img
          src={complianceDark}
          alt=""
          width="173px"
          height="177px"
          className={styles.complianceDark}
        />
        <img
          src={sbpDark}
          alt=""
          width="92px"
          height="106px"
          className={styles.sbpDark}
        />
      </Box.Image>
    </Box>
  );
};
