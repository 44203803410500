import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, useFormikContext } from "formik";
import type { FormikValues } from "formik";
import { Button, Input } from "components";
import styles from "./SignUp.module.scss";

type VerificationFormProps = {
  formError: string;
  onResendCode: () => void;
  onCancel: () => void;
};

export const VerificationForm: React.FC<VerificationFormProps> = ({
  formError,
  onResendCode,
  onCancel,
}) => {
  const { t } = useTranslation();

  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext() as FormikValues;

  const [isResendVisible, setIsResendVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsResendVisible(true);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Form>
      <h3 className={styles.title}>{t("verification.title")}</h3>
      <p className={styles.subtitle}>{t("verification.subtitle")}</p>

      {formError && (
        <div className={styles.formError}>
          <p>{formError}</p>
        </div>
      )}

      <Input
        label={t("verification.code")}
        name="code"
        inputMode="numeric"
        value={values.code}
        error={touched.code && errors.code}
        onChange={handleChange}
        onBlur={handleBlur}
        containerClassName={styles.code}
      />

      {isResendVisible && (
        <p>
          {t("verification.if-not-recieve")}

          <button
            type="button"
            className={styles.resendButton}
            onClick={onResendCode}
          >
            {t("verification.resend")}
          </button>
          {t("verification.or-change-email")}
        </p>
      )}

      <div className={styles.buttons}>
        <Button as="button" type="submit" variant="primary">
          {t("verification.verificate")}
        </Button>

        <Button as="button" type="submit" variant="light" onClick={onCancel}>
          {t("verification.change-email")}
        </Button>
      </div>
    </Form>
  );
};
