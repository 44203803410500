import React from "react";
import styles from "./AppLoader.module.scss";
import { Spinner } from "./Spinner";

export const AppLoader: React.FC = () => {
  return (
    <div className={styles.container}>
      <Spinner />
    </div>
  );
};
