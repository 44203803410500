import { ReactComponent as Logo } from "./logo.svg";
Logo.displayName = "Logo";
export { Logo };
export { ReactComponent as Telegram } from "./telegram.svg";
export { ReactComponent as HomeTelegram } from "./home-telegram.svg";
export { ReactComponent as ClientTechnicalSupport } from "./client-technical-support.svg";
export { ReactComponent as Reconciliation } from "./reconciliation.svg";
export { ReactComponent as Risks } from "./risks.svg";
export { ReactComponent as RiskMitigationBg } from "./risk-mitigation-bg.svg";
export { ReactComponent as ArrowLeft } from "./arrow-left.svg";
export { ReactComponent as ArrowRight } from "./arrow-right.svg";
export { ReactComponent as IconSpeed } from "./icon-speed.svg";
export { ReactComponent as IconSupport } from "./icon-support.svg";
export { ReactComponent as IconBlueSafe } from "./icon-blue-safe.svg";
export { ReactComponent as IconBlueComission } from "./icon-blue-comission.svg";
export { ReactComponent as IconBlueComfort } from "./icon-blue-comfort.svg";
export { ReactComponent as IconBlueSpeed } from "./icon-blue-speed.svg";
