import React, { useState } from "react";
import { DialogContext } from "helpers/contexts";

export const DialogProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  return (
    <DialogContext.Provider
      value={{
        isDialogOpened,
        setIsDialogOpened,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};
