import React, { useState, useRef, useEffect } from "react";
import closeIcon from "assets/icon-close.svg";
import styles from "./Dialog.module.scss";

interface DialogProps {
  isOpen: boolean;
  children: React.ReactNode;
  onClose?: () => void;
}

export const Dialog: React.FC<DialogProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  const [isDialogOpen, setDialogOpen] = useState(isOpen);
  const dialogRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    setDialogOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const dialogElement = dialogRef.current;
    if (dialogElement) {
      if (isDialogOpen) {
        dialogElement.showModal();
        document.body.style.setProperty("overflow", "hidden");
      } else {
        document.body.style.removeProperty("overflow");
        dialogElement.close();
      }
    }
  }, [isDialogOpen]);

  const handleCloseDialog = () => {
    if (onClose) {
      onClose();
    }
    setDialogOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === "Escape") {
      handleCloseDialog();
    }
  };

  return (
    <dialog ref={dialogRef} onKeyDown={handleKeyDown} className={styles.dialog}>
      <button
        type="button"
        className={styles.close}
        onClick={handleCloseDialog}
      >
        <img src={closeIcon} alt="" width="30" height="30" />
      </button>

      {children}
    </dialog>
  );
};
