import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import type { FormikValues } from "formik";
import { object } from "yup";
import { useValidators } from "helpers/hooks";
import { FormLoader } from "components";
import { ConnectionRequestForm } from "./ConnectionRequestForm";
import { ConnectionRequestAccepted } from "./ConnectionRequestAccepted";
import styles from "./ConnectionRequest.module.scss";

interface ConnectionRequestProps {}

const initialValues = {
  company: "",
  site: "",
  name: "",
  phone: "",
  email: "",
  type: "",
};

const API_URL = "/partnership/landing";

export const ConnectionRequest: React.FC<ConnectionRequestProps> = () => {
  const { t } = useTranslation();
  const {
    companyValidator,
    siteValidator,
    nameValidator,
    phoneValidator,
    emailValidator,
    typeValidator,
  } = useValidators();
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestAccepted, setIsRequestAccepted] = useState(false);
  const [networkError, setNetworkError] = useState("");

  function handleResetForm() {
    setIsRequestAccepted(false);
  }

  async function handleSubmit(values: FormikValues) {
    try {
      setIsLoading(true);

      const response = await fetch(API_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      });

      if (response.status === 200) {
        const resp = await response.json();

        if (resp.errors) {
          console.log("result.errors:", resp.errors);
        }

        if (resp.result === "ok") {
          setIsRequestAccepted(true);
        }

        return;
      }

      if (response.status === 400) {
        console.log("response.status 400:", response);
        return;
      }

      if (response.status === 500) {
        console.log("response.status 500:", response);
        return;
      }
    } catch (event: unknown) {
      console.log("event:", event);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isRequestAccepted && (
        <ConnectionRequestAccepted onResetForm={handleResetForm} />
      )}

      {!isRequestAccepted && (
        <div className={styles.connectionRequest}>
          <h3
            dangerouslySetInnerHTML={{ __html: t("connection-request.title") }}
          />
          <Formik
            initialValues={initialValues}
            validationSchema={object({
              company: companyValidator,
              site: siteValidator,
              name: nameValidator,
              phone: phoneValidator,
              email: emailValidator,
              type: typeValidator,
            })}
            onSubmit={handleSubmit}
          >
            <ConnectionRequestForm />
          </Formik>

          {isLoading && <FormLoader />}
        </div>
      )}
    </>
  );
};
