import React, { FunctionComponent, useState } from "react";
import { Item, ItemInterface } from "./Item";
import { Context } from "./Context";
import PropTypes from "prop-types";
import styles from "./Accordion.module.scss";
import cn from "classnames";

export type AccordionProps = React.HTMLAttributes<HTMLDListElement> & {
  counter?: boolean;
};

export interface AccordionInterface extends FunctionComponent<AccordionProps> {
  Item: ItemInterface;
}

export const Accordion: AccordionInterface = ({
  counter,
  children,
  className,
  ...props
}) => {
  const state = useState<React.RefObject<null> | null>(null);

  return (
    <Context.Provider value={state}>
      <dl
        {...props}
        className={cn(styles.accordion, {
          [styles.accordionCounter]: counter,
        })}
      >
        {children}
      </dl>
    </Context.Provider>
  );
};
Accordion.Item = Item;
Accordion.displayName = "Accordion";
Accordion.propTypes = {
  children: PropTypes.arrayOf((parent, index) => {
    const item = parent[index];
    if (React.isValidElement(item) && item.type === Item) {
      return null;
    }
    return new Error();
  }),
};
