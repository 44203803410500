import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import type { FormikValues } from "formik";
import { object } from "yup";
import { useSignIn } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { FormLoader } from "components";
import { useValidators } from "helpers/hooks";
import { api } from "api";
import { SignInForm } from "./SignInForm";
import bgImageEn from "assets/img/signIn.en.x1.5.png";
import bgImageRu from "assets/img/signIn.x1.5.png";
import styles from "./SignIn.module.scss";

const initialValues = {
  login: "",
  password: "",
};

export const SignIn: React.FC = () => {
  const { t } = useTranslation();
  const { loginValidator, passwordValidator } = useValidators();
  const signIn = useSignIn();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState("");

  const standardErrorText = t("errors.unknown-error");

  async function handleSubmit(values: FormikValues) {
    setIsLoading(true);
    setFormError("");

    try {
      const result = await api.signIn({
        login: values.login,
        password: values.password,
        type: "person",
      });

      if (result.error && result.error === "Confirmation required") {
        throw new Error(t("errors.confirmation-required"));
      } else {
        if (
          signIn({
            tokenType: "Bearer",
            token: result.token,
            expiresIn: result.expire_at,
            authState: {},
          })
        ) {
          navigate("/");
        } else {
          throw new Error(standardErrorText);
        }
      }
    } catch (error: unknown) {
      if (typeof error === "string") {
        setFormError(error);
      } else if (error instanceof Error) {
        setFormError(error.message);
      } else {
        setFormError(standardErrorText);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <section
      className={styles.page}
      style={{
        // @ts-expect-error: fix
        "--bg-image": `url(${i18n.language === "ru" ? bgImageRu : bgImageEn})`,
      }}
    >
      <div className={styles.container}>
        <h3 className={styles.title}>{t("sign-in.title")}</h3>
        <p className={styles.subtitle}>
          {t("sign-in.dont-have-account")}{" "}
          <NavLink to="/sign-up"> {t("sign-in.register")}</NavLink>
        </p>

        <Formik
          initialValues={initialValues}
          validationSchema={object({
            login: loginValidator,
            password: passwordValidator,
          })}
          onSubmit={handleSubmit}
        >
          <SignInForm formError={formError} />
        </Formik>
        {isLoading && <FormLoader />}
      </div>
    </section>
  );
};
