import React from "react";
import { useTranslation } from "react-i18next";
import { useDialogContext } from "helpers/hooks";
import iconApplication from "../../assets/icon-application.svg";
import iconManager from "../../assets/icon-manager.svg";
import iconSetting from "../../assets/icon-setting.svg";
import iconCheck from "../../assets/icon-check.svg";
import iconFinish from "../../assets/icon-finish.svg";
import supportFull from "../../assets/support-full.x1.5.png";
import {
  ClientTechnicalSupport,
  Reconciliation,
  Risks,
  Telegram,
} from "../../assets";
import {
  Box,
  Button,
  Container,
  IconCircle,
  PrimaryBlockProcessing,
} from "../../components";
import { IntegrationBox } from "./IntegrationBox";
import { AgreementBox } from "./AgreementBox";
import { CommissionBox } from "./CommissionBox";
import { FreeConnectBox } from "./FreeConnectBox";
import { MainFunctionBox } from "./MainFunctionBox";
import { WhiteLabelBox } from "./WhiteLabelBox";
import { ApiConnectionBox } from "./ApiConnectionBox";
import { ComponentsRiskMitigationBox } from "./ComponentsRiskMitigationBox";
import styles from "./Processing.module.scss";

export const Processing: React.FC = () => {
  const { t } = useTranslation();
  const { setIsDialogOpened } = useDialogContext();

  function handleClick() {
    setIsDialogOpened(true);
  }

  return (
    <main>
      <PrimaryBlockProcessing />

      <Container centered={true}>
        <h2 className="mb-20">{t("processing.advantages")}</h2>
      </Container>

      <nav className={styles.nav}>
        <a href="#advantages" className={styles.active}>
          {t("processing.advantages")}
        </a>
        <a href="#functional">{t("processing.functional")}</a>
        <a href="#how-to-connect">{t("processing.connect")}</a>
      </nav>

      <Container id="advantages" className={styles.advantages}>
        <IntegrationBox />
        <AgreementBox />
        <CommissionBox />
        <FreeConnectBox />
      </Container>

      <Container className={styles.support}>
        <h3>{t("processing.support.title")}</h3>

        <div className={styles.roundClock}>
          <p>{t("processing.support.subtitle")}</p>
        </div>

        <div className={styles.contacts}>
          <a href="mailto:info@3ds.money" className={styles.contactsEmail}>
            info@3ds.money
          </a>

          <p className={styles.contactsTelegram}>
            <Telegram />
            <a href="https://t.me/+asJlcl_6wwljMTAy">Telegram</a>
          </p>
        </div>

        <img className={styles.contactsImg} src={supportFull} alt="" />

        <div className={styles.supportFooter}>
          <div className={styles.supportFooterItem}>
            <IconCircle variant="light">
              <ClientTechnicalSupport />
            </IconCircle>
            <div
              dangerouslySetInnerHTML={{
                __html: t("processing.support.item-1"),
              }}
            />
          </div>

          <div className={styles.supportFooterItem}>
            <IconCircle variant="light">
              <Reconciliation />
            </IconCircle>
            <div>{t("processing.support.item-2")} </div>
          </div>

          <div className={styles.supportFooterItem}>
            <IconCircle variant="light">
              <Risks />
            </IconCircle>
            <div>{t("processing.support.item-3")}</div>
          </div>
        </div>
      </Container>

      <Container
        id="functional"
        centered={true}
        className={styles.functionalHeader}
      >
        <h2>{t("processing.functional")}</h2>
      </Container>

      <Container className={styles.functional}>
        <MainFunctionBox />
        <WhiteLabelBox />
        <ApiConnectionBox />
      </Container>

      <Container>
        <ComponentsRiskMitigationBox />
      </Container>

      <Container
        centered={true}
        id="how-to-connect"
        className={styles.howToConnect}
      >
        <div className={styles.howToConnectHeader}>
          <h2>{t("processing.how-to-connect.title")}</h2>
          <p>{t("processing.how-to-connect.subtitle")}</p>
        </div>

        <Box variant="light" className={styles.howToConnectBody}>
          <Box.Body>
            <ul className={styles.howToConnectSteps}>
              <li>
                <IconCircle
                  variant="dark"
                  src={iconApplication}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <p>{t("processing.how-to-connect.item-1")}</p>
              </li>
              <li>
                <IconCircle
                  variant="dark"
                  src={iconManager}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("processing.how-to-connect.item-2"),
                  }}
                />
              </li>
              <li>
                <IconCircle
                  variant="dark"
                  src={iconSetting}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("processing.how-to-connect.item-3"),
                  }}
                />
              </li>
              <li>
                <IconCircle
                  variant="dark"
                  src={iconCheck}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <p>{t("processing.how-to-connect.item-4")}</p>
              </li>
              <li>
                <IconCircle
                  variant="dark"
                  src={iconFinish}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <p>{t("processing.how-to-connect.item-5")}</p>
              </li>
            </ul>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                variant="secondary"
                wide={true}
                as="button"
                onClick={handleClick}
              >
                {t("processing.how-to-connect.connect")}
              </Button>
            </div>
          </Box.Body>
        </Box>
      </Container>
    </main>
  );
};
