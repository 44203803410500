import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { object } from "yup";
import type { FormikValues } from "formik";
import { api } from "api";
import { useValidators } from "helpers/hooks";
import { FormLoader } from "components";
import { ResetPasswordForm } from "./ResetPasswordForm";
import icons from "components/Icons";
import styles from "./ResetPassword.module.scss";

const ArrowLeftIcon = icons["arrow-left"];

const initialValues = {
  login: "",
};

export const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const { loginValidator } = useValidators();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState("");

  async function handleSubmit(values: FormikValues) {
    setIsLoading(true);
    setFormError("");

    try {
      const result = await api.forgotPassword({
        login: values.login,
        type: "person",
      });

      if (result.error) {
        throw new Error(result.error);
      } else {
        navigate(`/change-password?login=${result.login}`);
      }
    } catch (error: unknown) {
      if (typeof error === "string") {
        setFormError(error);
      } else if (error instanceof Error) {
        setFormError(error.message);
      } else {
        setFormError(standardErrorText);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const standardErrorText = t("errors.unknown-error");

  return (
    <section className={styles.page}>
      <div className={styles.container}>
        <NavLink to="/sign-in" className={styles.back}>
          <ArrowLeftIcon />
          {t("reset-password.back")}
        </NavLink>

        <h3 className={styles.title}>{t("reset-password.title")}</h3>
        <p className={styles.subtitle}>{t("reset-password.subtitle")}</p>

        <Formik
          initialValues={initialValues}
          validationSchema={object({
            login: loginValidator,
          })}
          onSubmit={handleSubmit}
        >
          <ResetPasswordForm formError={formError} />
        </Formik>
        {isLoading && <FormLoader />}
      </div>
    </section>
  );
};
