import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Box } from "components";
import bgImageEn from "assets/img/free-connect-sprite.en.x1.5.png";
import bgImageRu from "assets/free-connect-sprite.x1.5.png";
import styles from "./FreeConnectBox.module.scss";

export const FreeConnectBox: FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      variant="light"
      position="top"
      animated="scroll"
      className={styles.freeConnectBox}
    >
      <Box.Title
        dangerouslySetInnerHTML={{
          __html: t("processing.free-connect.title"),
        }}
      />
      <Box.Body>{t("processing.free-connect.body")}</Box.Body>
      <Box.Image
        style={{
          // @ts-expect-error: fix
          "--bg-image": `url(${i18n.language === "ru" ? bgImageRu : bgImageEn})`,
        }}
      >
        <div className={styles.freeConnectSpriteInput} />
        <div className={styles.freeConnectSpriteInputLabel} />
        <div className={styles.freeConnectSpriteThumb} />
        <div className={styles.freeConnectSpriteFront} />
      </Box.Image>
    </Box>
  );
};
