import React, { PropsWithChildren, useCallback, useRef, useState } from "react";
import { useLocation, useOutlet } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useDialogContext } from "helpers/hooks";
import { Header, Menu, Footer, Dialog, ConnectionRequest } from "components";
import styles from "./Layout.module.scss";

export const Layout: React.FC<PropsWithChildren> = ({ children = null }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { isDialogOpened, setIsDialogOpened } = useDialogContext();

  const location = useLocation();

  children = useOutlet();

  const ref = useRef<HTMLElement>();

  function handleToggleMenu() {
    setIsMenuOpened((prevState) => !prevState);
  }

  function handleCloseMenu() {
    setIsMenuOpened(false);
  }

  const handleDialogClose = useCallback(() => {
    setIsDialogOpened(false);
  }, [setIsDialogOpened]);

  return (
    <>
      <Header isMenuOpened={isMenuOpened} onToggleMenu={handleToggleMenu}>
        <Menu isMenuOpened={isMenuOpened} onCloseMenu={handleCloseMenu} />
      </Header>

      <TransitionGroup component={null}>
        <CSSTransition
          key={location.pathname}
          classNames={{
            enter: styles.switchEnter,
            enterActive: styles.switchEnterActive,
            enterDone: styles.switchEnterDone,
            exit: styles.switchExit,
            exitActive: styles.switchExitActive,
            exitDone: styles.switchExitDone,
            appear: styles.switchAppear,
            appearActive: styles.switchAppearActive,
            appearDone: styles.switchAppearDone,
          }}
          timeout={240}
          nodeRef={ref}
        >
          <article>{children}</article>
        </CSSTransition>
      </TransitionGroup>

      <Footer />

      <Dialog isOpen={isDialogOpened} onClose={handleDialogClose}>
        <ConnectionRequest />
      </Dialog>
    </>
  );
};
