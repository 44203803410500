import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Logo } from "assets";
import { Container, Menu } from "components";
import styles from "./Footer.module.scss";

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <Container className={styles.footer} as="footer">
      <div className={styles.firstColumn}>
        <Logo />
        <a href="/" className={cn(styles.copyright, "small")}>
          © {currentYear} 3DS money
        </a>
      </div>

      <div className={styles.secondColumn} style={{}}>
        <Menu />
        <a
          href="/docs/privacy-policy.pdf"
          className="gray"
          dangerouslySetInnerHTML={{ __html: t("global.privacy-policy") }}
        />
      </div>

      <div className={styles.thirdColumn}>
        <a href="/docs/terms-of-use.pdf" className="gray">
          {t("global.terms")}
        </a>
      </div>
    </Container>
  );
};
