import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { api } from "assets/img";
import { Box, Button } from "components";
import styles from "./ApiConnectionBox.module.scss";

export const ApiConnectionBox: FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      variant="light"
      animated="scroll"
      position="bottom"
      style={{ gridColumn: "span 12" }}
      className={styles.apiConnection}
    >
      <Box.Title
        dangerouslySetInnerHTML={{
          __html: t("processing.connection.title"),
        }}
      />
      <Box.Body>
        <p
          dangerouslySetInnerHTML={{
            __html: t("processing.connection.body"),
          }}
        />

        <p>
          <Button
            variant="secondary"
            as="a"
            href="/docs/3ds-money-api-documentation.pdf"
          >
            {t("processing.connection.button")}
          </Button>
        </p>
      </Box.Body>
      <Box.Image>
        <img src={api} alt="" width="184px" height="188px" />
      </Box.Image>
    </Box>
  );
};
