import React from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  PrimaryBlockHome,
  CountryBox,
  CountryTransferBox,
} from "components";
import { OnlineTransfer } from "./OnlineTransfer";
import { PreProcessing } from "./PreProcessing";
import { TechSupport } from "./TechSupport";
import styles from "./Home.module.scss";

export const Home: React.FC = () => {
  const { t } = useTranslation();

  return (
    <main>
      <PrimaryBlockHome />

      <Container centered={true}>
        <h2>{t("home.title")}</h2>
      </Container>

      <Container className={styles.products}>
        <OnlineTransfer />
        <PreProcessing />
      </Container>

      <Container columned={true} centered={true}>
        <h2 className="mb-0">{t("home.join-now-title")}</h2>
        <h5 className="mb-30 ta-c">{t("home.join-now-subtitle")}</h5>
      </Container>

      <Container className={styles.countries}>
        <CountryBox variant="light" className={styles.countryBox} />

        <CountryTransferBox
          variant="white"
          position="top"
          className={styles.countryTransferBox}
        />

        <TechSupport
          variant="white"
          position="right"
          className={styles.techSupport}
        />
      </Container>
    </main>
  );
};
