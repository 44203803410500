import React, {
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useMemo,
  useRef,
} from "react";
import { Title, TitleInterface } from "./Title";
import { Body, BodyInterface } from "./Body";
import PropTypes from "prop-types";

export type ItemProps = PropsWithChildren;

export interface ItemInterface extends FunctionComponent<ItemProps> {
  Title: TitleInterface;
  Body: BodyInterface;
}

export const Item: ItemInterface = (props) => <>{props.children}</>;

Item.propTypes = {
  children: PropTypes.arrayOf((parent, index) => {
    const item = parent[index];
    if (
      React.isValidElement(item) &&
      (item.type === Title || item.type === Body)
    ) {
      return null;
    }
    return new Error();
  }),
};
Item.displayName = "Tab.Item";
Item.Title = Title;
Item.Body = Body;
