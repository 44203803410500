import React from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { Logo } from "assets";
import styles from "./HeaderLight.module.scss";

export const HeaderLight = () => {
  return (
    <div className={cn(styles.header)}>
      <NavLink to="/">
        <Logo className={styles.logo} />
      </NavLink>
    </div>
  );
};
