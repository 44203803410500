import React, { PropsWithChildren } from "react";
import cn from "classnames";
import styles from "./Box.module.scss";

export type ImageProps = PropsWithChildren<
  Omit<React.ImgHTMLAttributes<HTMLImageElement>, "alt" | "src"> & {
    src?: string;
  }
>;

export const Image: React.FC<ImageProps> = ({ className = null, ...props }) => {
  return props.src ? (
    <img {...props} className={cn(styles.boxImage, className)} alt="" />
  ) : (
    <div {...props} className={cn(styles.boxImage, className)}>
      {props.children}
    </div>
  );
};
Image.displayName = "Box.Image";
