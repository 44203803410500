import React from "react";
import styles from "./FormLoader.module.scss";
import { Spinner } from "./Spinner";

export const FormLoader: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.spinnerWrapper}>
        <Spinner />

        {/* <div className={styles.loader}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
        >
          <mask
            id="mask0_4265_22694"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="28"
            height="28"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM14 25C20.0751 25 25 20.0751 25 14C25 7.92487 20.0751 3 14 3C7.92487 3 3 7.92487 3 14C3 20.0751 7.92487 25 14 25Z"
              fill="#D9D9D9"
            />
          </mask>
          <g mask="url(#mask0_4265_22694)">
            <rect y="-1.12024" width="31.36" height="31.36" fill="#D9D9D9" />
            <rect
              x="13.4395"
              y="-1.12024"
              width="17.92"
              height="15.68"
              fill="black"
            />
          </g>
        </svg>
      </div> */}
      </div>
    </div>
  );
};
