import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "react-auth-kit";
import { RouterProvider } from "react-router-dom";
import { DialogProvider } from "helpers/providers";
import reportWebVitals from "./reportWebVitals";
import { AppLoader } from "components";
import router from "./routes";
import "assets/css/global.scss";
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <DialogProvider>
      <AuthProvider authType={"cookie"} authName={"_auth"}>
        <RouterProvider router={router} fallbackElement={<AppLoader />} />
      </AuthProvider>
    </DialogProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
