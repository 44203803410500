import { t } from "i18next";

type SignInFromData = {
  login: string;
  password: string;
  type: string;
};

type SignUpFormData = {
  name: string;
  email: string;
  phone: string;
  citizenship: string;
  pass: string;
  currency: string;
  type: string;
  // login: string;
};

type VerificateEmailFormData = {
  email: string;
  code: string;
  type: string;
};

type ResendCodeFormData = {
  email: string;
  type: string;
};

type ForgotPasswordFormData = {
  login: string;
  type: string;
};

type ChangeForgottenPasswordFormData = {
  login: string;
  code: string;
  password: string;
  type: string;
};

export const api = {
  signIn: async (formData: SignInFromData) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(formData),
    };

    const response = await fetch("/api/v1/session", requestOptions);

    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401) {
      throw new Error(t("errors.unauthorized"));
    } else {
      throw new Error(t("errors.unknown-error"), {
        cause: response.statusText,
      });
    }
  },

  signUp: async (formData: SignUpFormData) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        ...formData,
        phone: formData.phone.replace(/\D/g, ""),
      }),
    };

    const response = await fetch("/api/v1/profile", requestOptions);

    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 422) {
      const { errors } = await response.json();

      // eslint-disable-next-line no-prototype-builtins
      if (errors.hasOwnProperty("phone")) {
        if (
          Array.isArray(errors.phone) &&
          errors.phone[0] === "has already been taken"
        ) {
          throw new Error(t("errors.phone-already-registered"));
        }
      }

      // eslint-disable-next-line no-prototype-builtins
      if (errors.hasOwnProperty("email")) {
        if (
          Array.isArray(errors.email) &&
          errors.email[0] === "has already been taken"
        ) {
          throw new Error(t("errors.email-already-registered"));
        }
      }

      throw new Error(t("errors.unknown-error"));
    } else {
      throw new Error(t("errors.unknown-error"), {
        cause: response.statusText,
      });
    }
  },

  verificateEmail: async (formData: VerificateEmailFormData) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        ...formData,
        code: encodeURIComponent(formData.code),
        email: formData.email,
      }),
    };

    const response = await fetch(
      "/api/v1/profile/confirm_email/",
      requestOptions,
    );

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error(t("errors.unknown-error"), {
        cause: response.statusText,
      });
    }
  },

  resendCode: async (formData: ResendCodeFormData) => {
    const response = await fetch(
      `/api/v1/profile/resend_code/?email=${encodeURIComponent(formData.email)}&type=${formData.type}`,
    );

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error(t("errors.unknown-error"), {
        cause: response.statusText,
      });
    }
  },

  forgotPassword: async (formData: ForgotPasswordFormData) => {
    const response = await fetch(
      `/api/v1/profile/forgot_password?login=${encodeURIComponent(formData.login)}&type=${formData.type}`,
    );

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error(t("errors.unknown-error"), {
        cause: response.statusText,
      });
    }
  },

  changeForgottenPassword: async (
    formData: ChangeForgottenPasswordFormData,
  ) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        ...formData,
        email: encodeURIComponent(formData.login),
        code: encodeURIComponent(formData.code),
        new_pass: encodeURIComponent(formData.password),
      }),
    };

    const response = await fetch(
      "/api/v1/profile/change_forgotten_pass",
      requestOptions,
    );

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error(t("errors.unknown-error"), {
        cause: response.statusText,
      });
    }
  },
};
