import React, { HTMLAttributes, ReactNode, forwardRef } from "react";
import InputMask from "@mona-health/react-input-mask";
import cn from "classnames";
import styles from "./Input.module.scss";

interface InputProps extends HTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  info?: ReactNode | string;
  mask?: string;
  value?: string;
  name?: string;
  type?: string;
  inputmode?: string;
  containerClassName?: string;
  rightButton?: ReactNode;
  onClickRightButton?: () => void;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    label,
    error,
    info = "",
    mask = "",
    type = "text",
    containerClassName,
    rightButton,
    onClickRightButton,
    ...props
  },
  ref,
) {
  return (
    <label className={cn(styles.input, containerClassName)}>
      <div className={cn(styles.container, !!props.value && styles.filled)}>
        <InputMask
          type={type}
          mask={mask}
          ref={ref}
          className={cn(styles.field, error && styles.invalid)}
          {...props}
        />
        <span className={styles.label}>{label}</span>
        {rightButton && (
          <button
            className={styles.button}
            onClick={onClickRightButton}
            type="button"
          >
            {rightButton}
          </button>
        )}
      </div>
      {info && <div className={styles.info}>{info}</div>}
      {error && <div className={styles.error}>{error}</div>}
    </label>
  );
});
