import {
  FaChevronLeft as ArrowLeft,
  FaChevronRight as ArrowRight,
  FaChevronDown as ArrowDown,
} from "react-icons/fa6";

export { ArrowLeft, ArrowRight, ArrowDown };

const icons = {
  "arrow-left": ArrowLeft,
  "arrow-right": ArrowRight,
  "arrow-down": ArrowDown,
};

export * from "./IconCircle";

export default icons;
