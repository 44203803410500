import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { object } from "yup";
import type { FormikValues } from "formik";
import { useValidators } from "helpers/hooks";
import { api } from "api";
import { FormLoader } from "components";
import { ChangePasswordForm } from "./ChangePasswordForm";
import styles from "./ChangePassword.module.scss";

const initialValues = {
  code: "",
  password: "",
  passwordConfirm: "",
};

export const ChangePassword: React.FC = () => {
  const { t } = useTranslation();
  const { codeValidator, passwordConfirmValidator, passwordValidator } =
    useValidators();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [login, setLogin] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState("");

  useEffect(() => {
    if (searchParams.has("login")) {
      setLogin(searchParams.get("login") as string);
    } else {
      setFormError(t("change-password.wrong-url"));
    }
  }, [searchParams, t]);

  const standardErrorText = t("errors.unknown-error");

  async function handleSubmit(values: FormikValues) {
    setIsLoading(true);
    setFormError("");

    try {
      const result = await api.changeForgottenPassword({
        login,
        code: values.code,
        password: values.password,
        type: "person",
      });

      if (result.error) {
        throw new Error(result.error);
      } else {
        if (result.status === "password_changed") {
          navigate("/sign-in");
        }
      }
    } catch (error: unknown) {
      if (typeof error === "string") {
        setFormError(error);
      } else if (error instanceof Error) {
        setFormError(error.message);
      } else {
        setFormError(standardErrorText);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <section className={styles.page}>
      <div className={styles.container}>
        <h3 className={styles.title}>{t("change-password.title")}</h3>

        {login && (
          <p>
            {t("change-password.subtitle")} {login}
          </p>
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={object({
            code: codeValidator,
            password: passwordValidator,
            passwordConfirm: passwordConfirmValidator,
          })}
          onSubmit={handleSubmit}
        >
          <ChangePasswordForm formError={formError} />
        </Formik>
        {isLoading && <FormLoader />}
      </div>
    </section>
  );
};
