import React, { useRef, useState } from "react";
import { Transition } from "react-transition-group";
import cn from "classnames";

type TransitionState = {
  show: boolean;
  transition: boolean;
  height?: string;
};

type CollapseProps<A extends keyof JSX.IntrinsicElements> = {
  as?: A;
  open: boolean;
  showClassName: string;
  transitionClassName: string;
  timeout: number;
} & JSX.IntrinsicElements[A];

export function Collapse<A extends keyof JSX.IntrinsicElements>({
  as: As,
  open,
  className,
  showClassName,
  transitionClassName,
  timeout,
  ...props
}: CollapseProps<A>) {
  const ref = useRef<HTMLElement>();
  const [state, setState] = useState<TransitionState>({
    show: open,
    transition: false,
  });
  const onEnter = () =>
    setState((prevState) => ({
      ...prevState,
      show: true,
      transition: true,
      height: "0",
    }));
  const onEntering = () => {
    const height = ref.current?.scrollHeight;
    setTimeout(
      () =>
        setState((prevState) => ({
          ...prevState,
          height: `${height}px`,
        })),
      1,
    );
  };
  const onEntered = () =>
    setState((prevState) => ({
      ...prevState,
      transition: false,
      height: undefined,
    }));
  const onExit = () => {
    const height = `${ref.current?.clientHeight}px`;
    setState((prevState) => ({
      ...prevState,
      show: false,
      transition: true,
      height,
    }));
  };
  const onExiting = () =>
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        height: "0",
      }));
    }, 1);
  const onExited = () =>
    setState((prevState) => ({
      ...prevState,
      transition: false,
      height: undefined,
    }));
  return (
    <Transition
      in={open}
      timeout={timeout}
      onEnter={onEnter}
      onEntering={onEntering}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExiting}
      onExited={onExited}
      nodeRef={ref}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error */}
      <As
        {...props}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        ref={ref}
        style={{ height: state.height }}
        className={cn(className, {
          [showClassName]: state.show,
          [transitionClassName]: state.transition,
        })}
      />
    </Transition>
  );
}
Collapse.defaultProps = {
  as: "div",
};
Collapse.displayName = "Collapse";
